import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxIntMessage,
  maxIntValue,
  maxString2000Message,
  maxString2000Value,
  maxString200Message,
  maxString200Value,
  minIntMessage,
  minIntValue,
  requiredFieldMessage
} from '../constants'
import { QuoteDto } from './quote'
import { JobDto } from './job'
import { CompanyDto } from './companies'
import { FinishedGoodsInventoryDto } from './finishedGoodsInventory'
import { AddressDto } from './address'

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class ShippingDto {
  id?: string = undefined
  billOfLadingNumber?: number = undefined
  displayBillOfLadingNumber?: string = undefined
  quote?: QuoteDto = undefined
  company?: CompanyDto = undefined
  scheduleDate?: string = undefined
  scheduleTime?: string = undefined
  carrier?: string = undefined
  movementNumber?: string = undefined
  shippingAddress?: AddressDto = undefined
  isThirdPartyFreightBilling?: boolean = false // The index of this value is used in the shippingValidationSchema, so if other values are added to ShippingDto above this, update that validation schema to match
  thirdPartyFreightBillingAddress?: string = undefined
  specialInstructions?: string = undefined
  shippingJobs?: ShippingJobDto[] = undefined
  totalBundles?: number = undefined
  totalWeight?: number = undefined
  trailerNumber?: string = undefined
  door?: string = undefined
  sealNumber?: string = undefined
  isActive?: boolean = true
}

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class ShippingJobDto {
  bundlesToShip?: number = undefined
  weight?: number = undefined
  job?: JobDto = undefined
  shipping?: ShippingDto = undefined
  shippingItems?: ShippingItemDto[] = undefined
}

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class ShippingItemDto {
  id?: string = undefined
  shippingJob?: ShippingJobDto = undefined
  finishedGoodsInventory?: FinishedGoodsInventoryDto = undefined
}

const shippingJobValidationSchema = yup.object<ShippingJobDto>({
  bundlesToShip: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .required(requiredFieldMessage)
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage)
})

export const shippingValidationSchema = yup.object<ShippingDto>({
  company: yup.object().required(requiredFieldMessage),
  scheduleDate: yup.string().required(requiredFieldMessage),
  scheduleTime: yup.string().required(requiredFieldMessage),
  carrier: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  movementNumber: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  thirdPartyFreightBillingAddress: yup
    .string()
    .when('isThirdPartyFreightBilling', (values, schema) => {
      if (values[0]) {
        return schema.required(requiredFieldMessage)
      } else {
        return schema.nullable()
      }
    }),
  shippingAddress: yup.object().required(requiredFieldMessage),
  specialInstructions: yup
    .string()
    .max(maxString2000Value, maxString2000Message)
    .nullable(),
  trailerNumber: yup.string().nullable().max(maxString200Value, maxString200Message),
  shippingJobs: yup.array().of(shippingJobValidationSchema),
  door: yup.string().nullable().max(maxString200Value, maxString200Message),
  sealNumber: yup.string().nullable().max(maxString200Value, maxString200Message)
})
