import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField
} from '@mui/material'
import { ExplanationAccordion } from 'components/_template/accordion'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import {
  ForkliftPalletLocationDto,
  ForkliftToDoDto,
  forkliftToDosScanBundleBarcodeValidationSchema
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { useRef } from 'react'

interface ForkliftToDosScanBundleBarcodeDialogProps {
  forkliftPalletLocations?: ForkliftPalletLocationDto[]
  forkliftToDo?: ForkliftToDoDto
  onClose: () => void
  open: boolean
  onSave: (values: ForkliftToDoDto) => Promise<void | ForkliftToDoDto>
}

export default function ForkliftToDosScanBundleBarcodeDialog({
  forkliftPalletLocations,
  forkliftToDo,
  onClose,
  open,
  onSave
}: ForkliftToDosScanBundleBarcodeDialogProps) {
  // These refs need to be cast to type any because TypeScript doesn't like them being initialized to null
  const skuNumberRef: any = useRef(null)
  const jobNumberRef: any = useRef(null)
  const bundleNumberRef: any = useRef(null)
  const dropOffForkliftPalletLocationRef: any = useRef(null)

  const onFocusSkuNumber = () => {
    skuNumberRef.current.focus()
  }
  const onFocusJobNumber = () => {
    jobNumberRef.current.focus()
  }
  const onFocusBundleNumber = () => {
    bundleNumberRef.current.focus()
  }
  const onFocusDropOffForkliftPalletLocation = () => {
    dropOffForkliftPalletLocationRef.current.focus()
  }

  return (
    // disableRestoreFocus seems necessary for the autofocus prop to function. See https://github.com/mui/material-ui/issues/33004#issuecomment-1455260156
    <Dialog open={open} disableRestoreFocus>
      <Formik
        enableReinitialize
        initialValues={forkliftToDo || new ForkliftToDoDto()}
        validateOnBlur
        validateOnChange
        validationSchema={forkliftToDosScanBundleBarcodeValidationSchema}
        onSubmit={(values, formikHelpers) => {
          // Clear the form values whether the save method saves successfully or throws an error
          onSave(values).finally(() => {
            onFocusSkuNumber()
            formikHelpers.resetForm()
          })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          const onSubmitForm = () => {
            // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
            // Otherwise, sometimes the field errors won't display even though the validation throws them
            validateForm().then(errors => {
              const errorKeys = Object.keys(errors)
              if (errorKeys.length === 0) {
                submitForm()
              } else {
                setTouched(setNestedObjectValues(errors, true))
                showFormErrorsPrompt(errors)
              }
            })
          }

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>
                    Scan Barcodes (or key in values) for each bundle
                  </DialogTitle>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      error={Boolean(touched.skuNumber && errors.skuNumber)}
                      fullWidth
                      helperText={touched.skuNumber && errors.skuNumber}
                      label='SKU #'
                      name='skuNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.skuNumber || ''}
                      inputRef={skuNumberRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.jobNumber && errors.jobNumber)}
                      fullWidth
                      helperText={touched.jobNumber && errors.jobNumber}
                      label='Job #'
                      name='jobNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.jobNumber || ''}
                      inputRef={jobNumberRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 2 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.bundleNumber && errors.bundleNumber)}
                      fullWidth
                      helperText={touched.bundleNumber && errors.bundleNumber}
                      label='Bundle #'
                      name='bundleNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bundleNumber || ''}
                      inputRef={bundleNumberRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 3 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.dropOffForkliftPalletLocation &&
                          errors.dropOffForkliftPalletLocation
                      )}
                      fullWidth
                      helperText={
                        touched.dropOffForkliftPalletLocation &&
                        errors.dropOffForkliftPalletLocation
                      }
                      label='Drop Off Location'
                      name='dropOffForkliftPalletLocation'
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue(
                          'dropOffForkliftPalletLocation',
                          JSON.parse(e.target.value)
                        )
                      }}
                      select
                      value={
                        values?.dropOffForkliftPalletLocation
                          ? JSON.stringify(
                              forkliftPalletLocations?.filter(
                                forkliftPalletLocation =>
                                  forkliftPalletLocation.id ===
                                  values.dropOffForkliftPalletLocation!.id
                              )[0]
                            )
                          : ''
                      }
                      inputRef={dropOffForkliftPalletLocationRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 3 }}
                    >
                      {forkliftPalletLocations
                        ?.filter(
                          forkliftPalletLocation =>
                            values.forkliftInstruction?.specialIdentifier ==
                              'PICK_UP_FROM_BANDER' &&
                            // Per Derek Nov-22-2024 ForkliftPalletLocations with a special identifer beginning with "WH_" are warehousing locations
                            forkliftPalletLocation.specialIdentifier?.substring(0, 3) ==
                              'WH_'
                        )
                        .map(forkliftPalletLocation => (
                          <MenuItem
                            key={forkliftPalletLocation.id}
                            value={JSON.stringify(forkliftPalletLocation)}
                          >
                            {forkliftPalletLocation.description}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      If the SKU #, Job #, and Bundle # combination does not have an
                      associated Forklift to Do, a warning will be displayed saying the
                      Forklift to Do cannot be found.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button
                  disabled={isSubmitting}
                  color='secondary'
                  onClick={() => {
                    onClose()
                  }}
                  tabIndex={6}
                  variant='text'
                >
                  CLOSE
                </Button>

                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    onSubmitForm()
                  }}
                  tabIndex={5}
                  variant='contained'
                >
                  SUBMIT
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
