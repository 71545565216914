import { ApiResponse, PersonDto } from 'dtos'
import { useSnackbar } from 'notistack'
import { createContext, useContext, useEffect, useState } from 'react'
import { errorHandling } from '../constants'
import apiClient from 'api'
import axios from 'axios'

export type Credentials = {
  email: string
  password: string
}

export const AuthContext = createContext<{
  signIn: (credentials: Credentials) => void
  signOut: () => void
  status: 'loading' | 'authorized' | 'unauthorized'
  user: PersonDto | null
  BANDING_ADD_EDIT: boolean
  BIG_JACK_TO_DOS_ADD_EDIT: boolean
  CAN_DUPLICATE_QUOTE: boolean
  CAN_RECONCILE_FINISHED_GOODS_INVENTORY: boolean
  CAN_RECONCILE_RAW_INVENTORY: boolean
  CAN_SCRAP_FINISHED_GOODS_INVENTORY: boolean
  CAN_SCRAP_RAW_INVENTORY: boolean
  COMPANIES_AND_PEOPLE_ADD_EDIT: boolean
  COMPANIES_AND_PEOPLE_READ_ONLY: boolean
  COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT: boolean
  CUSTOMER_ORDERS_REPORT: boolean
  FINISHED_GOODS_INVENTORY_ADD_EDIT: boolean
  FINISHED_GOODS_INVENTORY_ADJUSTMENTS_REPORT: boolean
  FINISHED_GOODS_INVENTORY_STOCK_REPORT: boolean
  FORKLIFT_TO_DOS_ADD_EDIT: boolean
  FORKLIFT_TRUCK_ADD_INVENTORY: boolean
  JOBS_SCHEDULING_ADD_EDIT: boolean
  LITTLE_JACK_TO_DOS_ADD_EDIT: boolean
  OPPORTUNITIES_ADD_EDIT: boolean
  OPPORTUNITIES_READ_ONLY: boolean
  RATES_ADD_EDIT: boolean
  RAW_INVENTORY_ADD_EDIT: boolean
  RAW_INVENTORY_ADJUSTMENTS_REPORT: boolean
  RAW_INVENTORY_STOCK_REPORT: boolean
  RECEIVING_ADD_EDIT: boolean
  RECEIVING_REPORT: boolean
  SALESPERSON: boolean
  SHIPPING_ADD_EDIT: boolean
  SHIPPING_REPORT: boolean
  USERS_ADD_EDIT: boolean
  VENDOR_ORDERS_ADD_EDIT: boolean
  VENDOR_ORDERS_REPORT: boolean
  VENDOR_USAGE_REPORT: boolean
} | null>(null)

export default function AuthProvider({ children }: React.PropsWithChildren) {
  const { enqueueSnackbar } = useSnackbar()
  const [user, setUser] = useState<PersonDto | null>(null)
  const [status, setStatus] = useState<'loading' | 'authorized' | 'unauthorized'>(
    'unauthorized'
  )

  // #region Permissions
  const [BANDING_ADD_EDIT, SET_BANDING_ADD_EDIT] = useState<boolean>(false)
  const [BIG_JACK_TO_DOS_ADD_EDIT, SET_BIG_JACK_TO_DOS_ADD_EDIT] =
    useState<boolean>(false)
  const [CAN_DUPLICATE_QUOTE, SET_CAN_DUPLICATE_QUOTE] = useState<boolean>(false)
  const [
    CAN_RECONCILE_FINISHED_GOODS_INVENTORY,
    SET_CAN_RECONCILE_FINISHED_GOODS_INVENTORY
  ] = useState<boolean>(false)
  const [CAN_RECONCILE_RAW_INVENTORY, SET_CAN_RECONCILE_RAW_INVENTORY] =
    useState<boolean>(false)
  const [CAN_SCRAP_FINISHED_GOODS_INVENTORY, SET_CAN_SCRAP_FINISHED_GOODS_INVENTORY] =
    useState<boolean>(false)
  const [CAN_SCRAP_RAW_INVENTORY, SET_CAN_SCRAP_RAW_INVENTORY] = useState<boolean>(false)
  const [COMPANIES_AND_PEOPLE_ADD_EDIT, SET_COMPANIES_AND_PEOPLE_ADD_EDIT] =
    useState<boolean>(false)
  const [COMPANIES_AND_PEOPLE_READ_ONLY, SET_COMPANIES_AND_PEOPLE_READ_ONLY] =
    useState<boolean>(false)
  const [
    COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT,
    SET_COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT
  ] = useState<boolean>(false)
  const [CUSTOMER_ORDERS_REPORT, SET_CUSTOMER_ORDERS_REPORT] = useState<boolean>(false)
  const [FINISHED_GOODS_INVENTORY_ADD_EDIT, SET_FINISHED_GOODS_INVENTORY_ADD_EDIT] =
    useState<boolean>(false)
  const [
    FINISHED_GOODS_INVENTORY_ADJUSTMENTS_REPORT,
    SET_FINISHED_GOODS_INVENTORY_ADJUSTMENTS_REPORT
  ] = useState<boolean>(false)
  const [
    FINISHED_GOODS_INVENTORY_STOCK_REPORT,
    SET_FINISHED_GOODS_INVENTORY_STOCK_REPORT
  ] = useState<boolean>(false)
  const [FORKLIFT_TO_DOS_ADD_EDIT, SET_FORKLIFT_TO_DOS_ADD_EDIT] =
    useState<boolean>(false)
  const [FORKLIFT_TRUCK_ADD_INVENTORY, SET_FORKLIFT_TRUCK_ADD_INVENTORY] =
    useState<boolean>(false)
  const [JOBS_SCHEDULING_ADD_EDIT, SET_JOBS_SCHEDULING_ADD_EDIT] =
    useState<boolean>(false)
  const [LITTLE_JACK_TO_DOS_ADD_EDIT, SET_LITTLE_JACK_TO_DOS_ADD_EDIT] =
    useState<boolean>(false)
  const [OPPORTUNITIES_ADD_EDIT, SET_OPPORTUNITIES_ADD_EDIT] = useState<boolean>(false)
  const [OPPORTUNITIES_READ_ONLY, SET_OPPORTUNITIES_READ_ONLY] = useState<boolean>(false)
  const [RATES_ADD_EDIT, SET_RATES_ADD_EDIT] = useState<boolean>(false)
  const [RAW_INVENTORY_ADD_EDIT, SET_RAW_INVENTORY_ADD_EDIT] = useState<boolean>(false)
  const [RAW_INVENTORY_ADJUSTMENTS_REPORT, SET_RAW_INVENTORY_ADJUSTMENTS_REPORT] =
    useState<boolean>(false)
  const [RAW_INVENTORY_STOCK_REPORT, SET_RAW_INVENTORY_STOCK_REPORT] =
    useState<boolean>(false)
  const [RECEIVING_ADD_EDIT, SET_RECEIVING_ADD_EDIT] = useState<boolean>(false)
  const [RECEIVING_REPORT, SET_RECEIVING_REPORT] = useState<boolean>(false)
  const [SALESPERSON, SET_SALESPERSON] = useState<boolean>(false)
  const [SHIPPING_ADD_EDIT, SET_SHIPPING_ADD_EDIT] = useState<boolean>(false)
  const [SHIPPING_REPORT, SET_SHIPPING_REPORT] = useState<boolean>(false)
  const [USERS_ADD_EDIT, SET_USERS_ADD_EDIT] = useState<boolean>(false)
  const [VENDOR_ORDERS_ADD_EDIT, SET_VENDOR_ORDERS_ADD_EDIT] = useState<boolean>(false)
  const [VENDOR_ORDERS_REPORT, SET_VENDOR_ORDERS_REPORT] = useState<boolean>(false)
  const [VENDOR_USAGE_REPORT, SET_VENDOR_USAGE_REPORT] = useState<boolean>(false)
  // #endregion

  useEffect(() => {
    checkIfUserSignedIn()

    return () => {}
  }, [])

  useEffect(() => {
    if (user && user.permissions) {
      SET_BANDING_ADD_EDIT(hasPermission('BANDING_ADD_EDIT'))
      SET_BIG_JACK_TO_DOS_ADD_EDIT(hasPermission('BIG_JACK_TO_DOS_ADD_EDIT'))
      SET_CAN_DUPLICATE_QUOTE(hasPermission('CAN_DUPLICATE_QUOTE'))
      SET_CAN_RECONCILE_FINISHED_GOODS_INVENTORY(
        hasPermission('CAN_RECONCILE_FINISHED_GOODS_INVENTORY')
      )
      SET_CAN_RECONCILE_RAW_INVENTORY(hasPermission('CAN_RECONCILE_RAW_INVENTORY'))
      SET_CAN_SCRAP_FINISHED_GOODS_INVENTORY(
        hasPermission('CAN_SCRAP_FINISHED_GOODS_INVENTORY')
      )
      SET_CAN_SCRAP_RAW_INVENTORY(hasPermission('CAN_SCRAP_RAW_INVENTORY'))
      SET_COMPANIES_AND_PEOPLE_ADD_EDIT(hasPermission('COMPANIES_AND_PEOPLE_ADD_EDIT'))
      SET_COMPANIES_AND_PEOPLE_READ_ONLY(hasPermission('COMPANIES_AND_PEOPLE_READ_ONLY'))
      SET_COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT(
        hasPermission('COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT')
      )
      SET_CUSTOMER_ORDERS_REPORT(hasPermission('CUSTOMER_ORDERS_REPORT'))
      SET_FINISHED_GOODS_INVENTORY_ADD_EDIT(
        hasPermission('FINISHED_GOODS_INVENTORY_ADD_EDIT')
      )
      SET_FINISHED_GOODS_INVENTORY_ADJUSTMENTS_REPORT(
        hasPermission('FINISHED_GOODS_INVENTORY_ADJUSTMENTS_REPORT')
      )
      SET_FINISHED_GOODS_INVENTORY_STOCK_REPORT(
        hasPermission('FINISHED_GOODS_INVENTORY_STOCK_REPORT')
      )
      SET_FORKLIFT_TO_DOS_ADD_EDIT(hasPermission('FORKLIFT_TO_DOS_ADD_EDIT'))
      SET_FORKLIFT_TRUCK_ADD_INVENTORY(hasPermission('FORKLIFT_TRUCK_ADD_INVENTORY'))
      SET_JOBS_SCHEDULING_ADD_EDIT(hasPermission('JOBS_SCHEDULING_ADD_EDIT'))
      SET_LITTLE_JACK_TO_DOS_ADD_EDIT(hasPermission('LITTLE_JACK_TO_DOS_ADD_EDIT'))
      SET_OPPORTUNITIES_ADD_EDIT(hasPermission('OPPORTUNITIES_ADD_EDIT'))
      SET_OPPORTUNITIES_READ_ONLY(hasPermission('OPPORTUNITIES_READ_ONLY'))
      SET_RATES_ADD_EDIT(hasPermission('RATES_ADD_EDIT'))
      SET_RAW_INVENTORY_ADD_EDIT(hasPermission('RAW_INVENTORY_ADD_EDIT'))
      SET_RAW_INVENTORY_ADJUSTMENTS_REPORT(
        hasPermission('RAW_INVENTORY_ADJUSTMENTS_REPORT')
      )
      SET_RAW_INVENTORY_STOCK_REPORT(hasPermission('RAW_INVENTORY_STOCK_REPORT'))
      SET_RECEIVING_ADD_EDIT(hasPermission('RECEIVING_ADD_EDIT'))
      SET_RECEIVING_ADD_EDIT(hasPermission('RECEIVING_ADD_EDIT'))
      SET_RECEIVING_REPORT(hasPermission('RECEIVING_REPORT'))
      SET_SALESPERSON(hasPermission('SALESPERSON'))
      SET_SHIPPING_ADD_EDIT(hasPermission('SHIPPING_ADD_EDIT'))
      SET_SHIPPING_REPORT(hasPermission('SHIPPING_REPORT'))
      SET_USERS_ADD_EDIT(hasPermission('USERS_ADD_EDIT'))
      SET_VENDOR_ORDERS_ADD_EDIT(hasPermission('VENDOR_ORDERS_ADD_EDIT'))
      SET_VENDOR_ORDERS_REPORT(hasPermission('VENDOR_ORDERS_REPORT'))
      SET_VENDOR_USAGE_REPORT(hasPermission('VENDOR_USAGE_REPORT'))
    }
  }, [user])

  const hasPermission = (permissionSpecialIdentifier: string): boolean => {
    if (user && user.permissions) {
      return user.permissions
        .filter(permission => permission.specialIdentifier)
        .map(permission => permission.specialIdentifier!.toUpperCase())
        .includes(permissionSpecialIdentifier.toUpperCase())
    }

    return false
  }

  const signIn = async ({ email, password }: Credentials) => {
    setStatus('loading')
    await apiClient
      .post(
        '/api/account/sign-in',
        { email, password },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then(({ data: { value } }) => {
        setUser(value)
        setStatus('authorized')
      })
      .catch((errors: string[]) => {
        setStatus('unauthorized')
        errorHandling(errors)
      })
  }

  const signOut = async () => {
    await apiClient
      .post('/api/account/sign-out')
      .then(({ data }) => {
        setUser(null)
        setStatus('unauthorized')
        window.location.assign('/account/sign-in')
      })
      .catch((errors: string[]) => {
        setStatus('unauthorized')
        errorHandling(errors)
      })
  }

  // Persist through refreshes, etc
  const checkIfUserSignedIn = async () => {
    setStatus('loading')
    // This route must use axios not apiClient because the interceptor causes an infinite loop
    await axios
      .get<ApiResponse<PersonDto>>('/api/account/session')
      .then(({ data: { value } }) => {
        setUser(value ?? null)
        if (value) {
          setStatus('authorized')
        } else {
          setStatus('unauthorized')
        }
      })
      .catch(errors => {
        setStatus('unauthorized')
        // This method does not throw snackbars on a 401 error because it would throw an error every time the page reloads while the user is signed out
        if (errors.response.status !== 401) {
          errorHandling(errors)
        }
      })
  }

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        status,
        user,
        BANDING_ADD_EDIT,
        BIG_JACK_TO_DOS_ADD_EDIT,
        CAN_DUPLICATE_QUOTE,
        CAN_RECONCILE_FINISHED_GOODS_INVENTORY,
        CAN_RECONCILE_RAW_INVENTORY,
        CAN_SCRAP_FINISHED_GOODS_INVENTORY,
        CAN_SCRAP_RAW_INVENTORY,
        COMPANIES_AND_PEOPLE_ADD_EDIT,
        COMPANIES_AND_PEOPLE_READ_ONLY,
        COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT,
        CUSTOMER_ORDERS_REPORT,
        FINISHED_GOODS_INVENTORY_ADD_EDIT,
        FINISHED_GOODS_INVENTORY_ADJUSTMENTS_REPORT,
        FINISHED_GOODS_INVENTORY_STOCK_REPORT,
        FORKLIFT_TO_DOS_ADD_EDIT,
        FORKLIFT_TRUCK_ADD_INVENTORY,
        JOBS_SCHEDULING_ADD_EDIT,
        LITTLE_JACK_TO_DOS_ADD_EDIT,
        OPPORTUNITIES_ADD_EDIT,
        OPPORTUNITIES_READ_ONLY,
        RATES_ADD_EDIT,
        RAW_INVENTORY_ADD_EDIT,
        RAW_INVENTORY_ADJUSTMENTS_REPORT,
        RAW_INVENTORY_STOCK_REPORT,
        RECEIVING_ADD_EDIT,
        RECEIVING_REPORT,
        SALESPERSON,
        SHIPPING_ADD_EDIT,
        SHIPPING_REPORT,
        USERS_ADD_EDIT,
        VENDOR_ORDERS_ADD_EDIT,
        VENDOR_ORDERS_REPORT,
        VENDOR_USAGE_REPORT
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)!
