import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxDateMessage,
  maxDateValue,
  maxDecimal194Message,
  maxDecimal194Value,
  maxIntMessage,
  maxIntValue,
  maxLargeIntMessage,
  maxLargeIntValue,
  maxString100Message,
  maxString100Value,
  maxString200Message,
  maxString200Value,
  maxString50Message,
  maxString50Value,
  minDateMessage,
  minDateValue,
  minIntMessage,
  minIntValue,
  minNonZeroDecimal194Message,
  minNonZeroDecimal194Value,
  minNonZeroIntMessage,
  minNonZeroIntValue,
  minPositiveDecimal194Message,
  minPositiveDecimal194Value,
  requiredFieldMessage
} from '../constants'
import { CompanyDto } from './companies'
import {
  RatesAssemblyAdderDto,
  RatesBlendAdderDto,
  RatesInkDto,
  RatesPaperDto,
  RatesScoringAdderDto
} from './rates'
import { BoardTypeDto } from './boardTypes'
import { OpportunityStatusDto } from './opportunityStatus'
import { QuoteLineItemDesignFileDto } from './quoteLineItemDesignFile'
import { VendorOrderDto } from './vendorOrder'

export class QuoteLineItemDto {
  id?: string = undefined
  vendorOrders?: VendorOrderDto[] = []

  // Line Item Information Card
  specGeneratedOrProvidedBy?: string = '' // This field must be initialized to '' instead of undefined, otherwise it will not populate with field values
  skuNumber?: string = undefined
  quotationRefNumber?: string = undefined
  specDate?: string = undefined
  customerPartNumber?: string = undefined
  testRunQuantity?: number = undefined
  minimumOrderRunQuantity?: number = undefined
  committedQuantity?: number = undefined
  contractDuration?: number = undefined
  lineItemNotes?: string = undefined
  quoteLineItemDesignFiles?: QuoteLineItemDesignFileDto[] = []
  jobCenter?: string = undefined
  dieCut?: string = undefined

  // Sheet Dimensions Card
  grossWidth?: number = undefined
  grossLength?: number = undefined
  grossSquareFeet?: number = undefined
  netWidth?: number = undefined
  netLength?: number = undefined
  netSquareFeet?: number = undefined
  msfPerUnit?: number = undefined

  // Paper Card
  ratesPaper?: RatesPaperDto = undefined
  vendor?: string = undefined
  boardType?: BoardTypeDto = undefined
  flute?: string = undefined
  ectMullen?: string = undefined
  quotePaperAdders?: QuotePaperAdderDto[] = []

  // Paper Cost Card
  paperCostSetupFee?: number = undefined
  paperCostPerMsf?: number = undefined
  paperCostPerMpcs?: number = undefined
  paperCostSubtotalSetupFee?: number = undefined
  paperCostSubtotalCostPerMsf?: number = undefined
  paperCostSubtotalCostPerMpcs?: number = undefined

  // Paper Adders Cost Card
  paperAddersCostSubtotalSetupFee?: number = undefined
  paperAddersCostSubtotalCostPerMsf?: number = undefined
  paperAddersCostSubtotalCostPerMpcs?: number = undefined

  // Sesame Tape Card
  sesameTapeType?: string = undefined
  sesameTapeInches?: number = undefined
  sesameTapeSubtotal?: number = undefined
  sesameTapeSubtotalCostPerMsf?: number = undefined
  sesameTapeSubtotalCostPerMpcs?: number = undefined

  // Blend Card
  blendLiner1?: string = undefined
  blendMedium1?: string = undefined
  blendLiner2?: string = undefined
  blendMedium2?: string = undefined
  blendLiner3?: string = undefined
  blendMedium3?: string = undefined
  blendLiner4?: string = undefined
  paperCode?: string = undefined
  quoteBlendAdders?: QuoteBlendAdderDto[] = []

  // Blend Cost Card
  blendCostSubtotalSetupFee?: number = undefined
  blendCostSubtotalCostPerMsf?: number = undefined
  blendCostSubtotalCostPerMpcs?: number = undefined

  // Assembly Card
  assemblyCustomerOrderQuantity?: number = undefined
  assemblyOrderMsf?: number = undefined
  assemblyFinishedGoodUnitQuantityPerBundle?: number = undefined
  assemblyMsf?: number = undefined
  assemblyBundleConfiguration?: string = undefined
  assemblyBasisWeight?: number = undefined
  assemblyWeightMpcs?: number = undefined
  assemblyTonPerMpcs?: number = undefined
  assemblyOuts?: number = undefined
  assemblyStyle?: string = undefined
  assemblyJoint?: string = undefined
  assemblyUnitType?: string = undefined
  quoteAssemblyAdders?: QuoteAssemblyAdderDto[] = []

  // Assembly Cost Card
  assemblyCostSubtotalSetupFee?: number = undefined
  assemblyCostSubtotalCostPerMsf?: number = undefined
  assemblyCostSubtotalCostPerMpcs?: number = undefined

  // Scoring Card
  scoringCorrugatorScoring1?: number = undefined
  scoringCorrugatorScoring2?: number = undefined
  scoringCorrugatorScoring3?: number = undefined
  scoringCorrugatorScoring4?: number = undefined
  scoringCorrugatorScoring5?: number = undefined
  scoringCorrugatorScoring6?: number = undefined
  scoringCorrugatorScoring7?: number = undefined
  scoringCorrugatorScoring8?: number = undefined
  scoringCorrugatorScoring9?: number = undefined
  scoringCorrugatorScoring10?: number = undefined
  scoringCorrugatorScoring11?: number = undefined
  scoringCorrugatorScoring12?: number = undefined
  scoringCorrugatorScoring13?: number = undefined
  scoringCorrugatorScoring14?: number = undefined
  scoringCorrugatorScoring15?: number = undefined
  scoringCorrugatorScoring16?: number = undefined
  scoringCumulativeWidth?: number = undefined
  quoteScoringAdders?: QuoteScoringAdderDto[] = []

  // Scoring Cost Card
  scoringCostSubtotalSetupFee?: number = undefined
  scoringCostSubtotalCostPerMsf?: number = undefined
  scoringCostSubtotalCostPerMpcs?: number = undefined

  // Tooling Card
  toolingCuttingDieCost?: number = undefined
  toolingDieQuantity?: number = undefined
  cuttingDieOwnership?: string = '' // This field must be initialized to '' instead of undefined, otherwise it will not populate with field values
  toolingPrint?: boolean = undefined
  toolingColorsCount?: number = undefined
  toolingPrintPlateCost?: number = undefined
  toolingPrintPlateQuantity?: number = undefined
  toolingPrintPanelsCount?: number = undefined
  toolingPrintPlateOwnership?: string = '' // This field must be initialized to '' instead of undefined, otherwise it will not populate with field values

  // Inks Card
  inksCoveragePercentage?: number = undefined
  ratesInkPanel1Color1?: RatesInkDto = undefined
  ratesInkPanel1Color2?: RatesInkDto = undefined
  ratesInkPanel1Color3?: RatesInkDto = undefined
  ratesInkPanel1Color4?: RatesInkDto = undefined
  ratesInkPanel2Color1?: RatesInkDto = undefined
  ratesInkPanel2Color2?: RatesInkDto = undefined
  ratesInkPanel2Color3?: RatesInkDto = undefined
  ratesInkPanel2Color4?: RatesInkDto = undefined
  ratesInkPanel3Color1?: RatesInkDto = undefined
  ratesInkPanel3Color2?: RatesInkDto = undefined
  ratesInkPanel3Color3?: RatesInkDto = undefined
  ratesInkPanel3Color4?: RatesInkDto = undefined
  ratesInkPanel4Color1?: RatesInkDto = undefined
  ratesInkPanel4Color2?: RatesInkDto = undefined
  ratesInkPanel4Color3?: RatesInkDto = undefined
  ratesInkPanel4Color4?: RatesInkDto = undefined

  // Inks Cost Card
  inksCostSetupCost?: number = undefined
  inksCostValue?: number = undefined
  inksCostSubtotal?: number = undefined

  // End Cost Adders Cost Card
  endCostAddersCostSubtotalSetupFee?: number = undefined
  endCostAddersCostSubtotalCostPerMsf?: number = undefined
  endCostAddersCostSubtotalCostPerMpcs?: number = undefined
  quoteEndCostAdders?: QuoteEndCostAdderDto[] = []

  // Variable Costs Card
  variableCostsLaborCost?: number = undefined
  variableCostsLaborCostPerMsf?: number = undefined
  variableCostsLaborCostPerMpcs?: number = undefined
  variableCostsJobCenterPiecePerHour?: number = undefined
  variableCostsJobCenterCostPerHour?: number = undefined // Not displayed on Quote Details
  variableCostsJobCenterSetupCost?: number = undefined // Not displayed on Quote Details
  variableCostsBanderLaborCost?: number = undefined
  variableCostsBanderLaborCostPerMsf?: number = undefined
  variableCostsBanderLaborCostPerMpcs?: number = undefined
  variableCostsMaterialHandlingCost?: number = undefined
  variableCostsMaterialHandlingCostPerMsf?: number = undefined
  variableCostsMaterialHandlingCostPerMpcs?: number = undefined
  variableCostsPalletCostBundleInput?: number = undefined
  variableCostsPalletCostBundleCost?: number = undefined
  variableCostsPalletCostBundleCostPerMpcs?: number = undefined
  variableCostsPenaltyInput?: number = undefined
  variableCostsPenaltyCost?: number = undefined
  variableCostsPenaltyCostPerMpcs?: number = undefined
  variableCostsWarehousingPercentInput?: number = undefined
  variableCostsWarehousingPercentCost?: number = undefined
  variableCostsWarehousingPercentCostPerMpcs?: number = undefined
  variableCostsMiscellaneousCostInput?: number = undefined
  variableCostsMiscellaneousCostCost?: number = undefined
  variableCostsMiscellaneousCostCostPerMpcs?: number = undefined
  variableCostsFreightInput?: number = undefined
  variableCostsFreightCost?: number = undefined
  variableCostsFreightCostPerMpcs?: number = undefined
  variableCostsSubtotalCost?: number = undefined
  variableCostsSubtotalCostPerMsf?: number = undefined
  variableCostsSubtotalCostPerMpcs?: number = undefined

  // Summary Card
  // The "Paper Cost", "Paper Adders Cost", "Blend Cost", "Assembly Cost", "Scoring Cost", and "End Cost Adders Cost"
  // rows are exact copies of the "Subtotal" rows from their respective cards
  summaryPaperSubtotalSetupFee?: number = undefined
  summaryPaperSubtotalCostPerMsf?: number = undefined
  summaryPaperSubtotalCostPerMpcs?: number = undefined
  summaryPaperTotalsCostPerMpcs?: number = undefined
  // The "Inks Cost" row is an exact copy of the "Subtotal" row from the Inks Cost Card
  // The "Variable Cost" row "Copst per MPCS" cell is a copy of the variableCostsSubtotalCostPerMpcs value
  summaryManufacturingSubtotalSetupFee?: number = undefined
  summaryManufacturingSubtotalCostPerMpcs?: number = undefined
  summaryVariableTotalsCostPerMpcs?: number = undefined
  summaryPaperAndVariableTotalsCostPerMpcs?: number = undefined
  // summaryWastePercentInput is copied from Rates Global, it is not an input field here
  summaryWastePercentInput?: number = undefined
  summaryWastePercentCostPerMpcs?: number = undefined
  summaryWastePercentSubtotalCostPerMpcs?: number = undefined
  summaryPaperVariableAndWasteTotalsCostPerMpcs?: number = undefined
  // summarySgaPercentInput is copied from Rates Global, it is not an input field here
  summarySgaPercentInput?: number = undefined
  summarySgaPercentCostPerMpcs?: number = undefined
  summaryMarkupPercentInput?: number = undefined
  summaryMarkupPercentCostPerMpcs?: number = undefined
  summaryTotalSellPriceCostPerMpcs?: number = undefined

  checkValuesOrderValue?: number = undefined
  checkValuesXBoard?: number = undefined
  checkValuesTotalContributions?: number = undefined
  checkValuesContributionsPercent?: number = undefined
  checkValuesSellPricePercentBoard?: number = undefined
  checkValuesOriginalCostPerPiece?: number = undefined
  checkValuesCostPerPiece?: number = undefined
  checkValuesOriginalExtendedCost?: number = undefined
  checkValuesExtendedCost?: number = undefined
  checkValuesVendorOrderQuantity?: number = undefined
  checkValuesVendorOrderCostPerPiece?: number = undefined
  checkValuesVendorOrderExtendedCost?: number = undefined
  checkValuesTotalPaperCostPerOut?: number = undefined

  isActive: boolean = true
}

export class QuoteRevisionDto {
  revisionNumber?: number = undefined
  revisionReason?: string = undefined
  quoteValidUntil?: string = undefined
  paymentTerms?: string = undefined
  currency?: string = undefined
  methodOfShipment?: string = undefined
  leadTimeForEachOrder?: string = undefined
  quoteLineItem?: QuoteLineItemDto = new QuoteLineItemDto() // undefined
}

export class QuoteDto {
  id?: string = undefined
  // This value is used for determining if the form fields should be disabled due to the parent opportunity being sold
  opportunityLastStatus?: OpportunityStatusDto = undefined
  // Used for SKU # prefix/input adornment
  readonly_company?: CompanyDto = undefined

  rfqReceivedDate?: string = undefined
  quoteRevision?: QuoteRevisionDto = new QuoteRevisionDto() // undefined
  readonly_displayOpportunityNumber?: string = undefined
}

export class DuplicateQuoteDto {
  quoteId?: string = undefined
  opportunityToUse?: string = '' // This field must be initialized to '' instead of undefined, otherwise it will not populate with field values
  skuNumber?: string = undefined
}

export const quoteLineItemValidationSchema = yup.object({
  specGeneratedOrProvidedBy: yup
    .string()
    .max(maxString50Value, maxString50Message)
    .required(requiredFieldMessage),
  skuNumber: yup
    .string()
    .max(maxString50Value, maxString50Message)
    .required(requiredFieldMessage),
  quotationRefNumber: yup
    .string()
    .max(maxString100Value, maxString100Message)
    .required(requiredFieldMessage),
  specDate: yup
    .date()
    .typeError(fieldTypeMessage('Date'))
    .min(minDateValue, minDateMessage)
    .max(maxDateValue, maxDateMessage)
    .required(requiredFieldMessage),
  customerPartNumber: yup
    .string()
    .max(maxString50Value, maxString50Message)
    .required(requiredFieldMessage),
  testRunQuantity: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxLargeIntValue, maxLargeIntMessage)
    .required(requiredFieldMessage),
  minimumOrderRunQuantity: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxLargeIntValue, maxLargeIntMessage)
    .required(requiredFieldMessage),
  committedQuantity: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxLargeIntValue, maxLargeIntMessage)
    .required(requiredFieldMessage),
  contractDuration: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .required(requiredFieldMessage),
  lineItemNotes: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  grossWidth: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minNonZeroDecimal194Value, minNonZeroDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .required(requiredFieldMessage),
  grossLength: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minNonZeroDecimal194Value, minNonZeroDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .required(requiredFieldMessage),
  netWidth: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .required(requiredFieldMessage),
  netLength: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .required(requiredFieldMessage),
  jobCenter: yup.string().required(requiredFieldMessage),
  dieCut: yup.string().required(requiredFieldMessage),
  vendor: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  boardType: yup.object().required(requiredFieldMessage),
  flute: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  ectMullen: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  blendLiner1: yup.string().max(maxString200Value, maxString200Message).nullable(),
  blendMedium1: yup.string().max(maxString200Value, maxString200Message).nullable(),
  blendLiner2: yup.string().max(maxString200Value, maxString200Message).nullable(),
  blendMedium2: yup.string().max(maxString200Value, maxString200Message).nullable(),
  blendLiner3: yup.string().max(maxString200Value, maxString200Message).nullable(),
  blendMedium3: yup.string().max(maxString200Value, maxString200Message).nullable(),
  blendLiner4: yup.string().max(maxString200Value, maxString200Message).nullable(),
  assemblyCustomerOrderQuantity: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minNonZeroIntValue, minNonZeroIntMessage)
    .max(maxLargeIntValue, maxLargeIntMessage)
    .required(requiredFieldMessage),
  assemblyFinishedGoodUnitQuantityPerBundle: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minNonZeroIntValue, minNonZeroIntMessage)
    .max(maxLargeIntValue, maxLargeIntMessage)
    .required(requiredFieldMessage),
  assemblyOuts: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minNonZeroIntValue, minNonZeroIntMessage)
    .max(maxIntValue, maxIntMessage)
    .required(requiredFieldMessage),
  assemblyUnitType: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  scoringCorrugatorScoring1: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring2: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring3: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring4: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring5: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring6: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring7: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring8: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring9: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring10: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring11: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring12: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring13: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring14: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring15: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  scoringCorrugatorScoring16: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  toolingCuttingDieCost: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  toolingDieQuantity: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage)
    .nullable(),
  cuttingDieOwnership: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .nullable(),
  toolingPrint: yup.boolean().nullable(),
  toolingColorsCount: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage)
    .nullable(),
  toolingPrintPlateCost: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  toolingPrintPlateQuantity: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage)
    .nullable(),
  toolingPrintPanelsCount: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage)
    .nullable(),
  toolingPrintPlateOwnership: yup
    .string()
    .max(maxString50Value, maxString50Message)
    .nullable(),
  inksCoveragePercentage: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  variableCostsPenaltyInput: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  variableCostsWarehousingPercentInput: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  variableCostsMiscellaneousCostInput: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  variableCostsFreightInput: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .nullable(),
  summaryMarkupPercentInput: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  isActive: yup.bool()
})

export const quoteRevisionValidationSchema = yup.object({
  revisionReason: yup
    .string()
    .max(maxString50Value, maxString50Message)
    .required(requiredFieldMessage),
  quoteValidUntil: yup
    .date()
    .typeError(fieldTypeMessage('Date'))
    .min(minDateValue, minDateMessage)
    .max(maxDateValue, maxDateMessage)
    .required(requiredFieldMessage),
  paymentTerms: yup
    .string()
    .max(maxString50Value, maxString50Message)
    .required(requiredFieldMessage),
  currency: yup
    .string()
    .max(maxString50Value, maxString50Message)
    .required(requiredFieldMessage),
  methodOfShipment: yup
    .string()
    .max(maxString50Value, maxString50Message)
    .required(requiredFieldMessage),
  leadTimeForEachOrder: yup
    .string()
    .max(maxString50Value, maxString50Message)
    .required(requiredFieldMessage),
  quoteLineItem: quoteLineItemValidationSchema
})

export const quoteValidationSchema = yup.object<QuoteDto>({
  rfqReceivedDate: yup
    .date()
    .typeError(fieldTypeMessage('Date'))
    .min(minDateValue, minDateMessage)
    .max(maxDateValue, maxDateMessage)
    .required(requiredFieldMessage),
  quoteRevision: quoteRevisionValidationSchema
})

export const duplicateQuoteValidationSchema = yup.object({
  opportunityToUse: yup
    .string()
    .max(maxString50Value, maxString50Message)
    .required(requiredFieldMessage),
  skuNumber: yup
    .string()
    .max(maxString50Value, maxString50Message)
    .required('requiredFieldMessage')
})

export class QuoteAssemblyAdderDto {
  ect?: number = undefined
  adder?: string = undefined
  adderSetup?: number = undefined
  costPerMsf?: number = undefined
  costPerMpcs?: number = undefined
  ratesAssemblyAdder?: RatesAssemblyAdderDto = undefined
}

export class QuoteAssemblyUnitTypeDto {
  id: string = ''
  name: string = ''
  description: string = ''
  specialIdentifier?: string = ''
  sortOrder?: number = 0
  weight: number = 0
}

export class QuoteAssemblyJointDto {
  id: string = ''
  name: string = ''
  description: string = ''
  specialIdentifier?: string = ''
  sortOrder?: number = 0
}

export class QuoteAssemblyStyleDto {
  id: string = ''
  name: string = ''
  description: string = ''
  specialIdentifier?: string = ''
  sortOrder?: number = 0
}

export class QuoteBlendAdderDto {
  ect?: number = undefined
  adder?: string = undefined
  adderSetup?: number = undefined
  costPerMsf?: number = undefined
  costPerMpcs?: number = undefined
  ratesBlendAdder?: RatesBlendAdderDto = undefined
}

export class QuoteEndCostAdderDto {
  id?: string = undefined
  quoteLineItemId?: string = undefined
  UNSAFE_AdderId?: string = undefined
  ect?: number = undefined
  adder?: string = undefined
  adderSetup?: number = undefined
  costPerMsf?: number = undefined
  costPerMpcs?: number = undefined
  description?: string = undefined
  sortOrder?: number = undefined
}

export class QuotePaperAdderDto {
  ect?: string = undefined
  adder?: string = undefined
  adderSetup?: number = undefined
  costPerMsf?: number = undefined
  costPerMpcs?: number = undefined
  ratesPaperAdder?: RatesPaperDto = undefined
}

export class QuoteScoringAdderDto {
  ect?: string = undefined
  adder?: string = undefined
  adderSetup?: number = undefined
  costPerMsf?: number = undefined
  costPerMpcs?: number = undefined
  ratesScoringAdder?: RatesScoringAdderDto = undefined
}
