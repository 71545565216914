import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import {
  AddressDto,
  CompanyDto,
  PersonDto,
  QuoteDto,
  QuoteLineItemDto,
  QuoteRevisionDto,
  ReportPurchaseOrderDto,
  VendorOrderDto
} from 'dtos'
import moment from 'moment'
import { format, formats } from 'theme'

const styles = StyleSheet.create({
  cell: {
    textAlign: 'left',
    width: '33%'
  },
  column: {
    flex: 1,
    padding: 5 // Adjust as needed
  },
  fullWidthCell: {
    textAlign: 'left',
    width: '100%'
  },
  headerCellWhite: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    width: '33%'
  },
  headerCellGreen: {
    color: 'green',
    fontWeight: 'bold',
    textAlign: 'left',
    width: '33%'
  },
  horizontalLine: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    marginBottom: 5,
    marginTop: 5
  },
  logo: {
    height: 75,
    width: 250
  },
  page: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    fontSize: 10
  },
  section: {
    flexGrow: 1,
    margin: 10,
    padding: 10
  },
  table: {
    alignItems: 'center',
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    flexDirection: 'row'
  },
  tableGreen: {
    alignItems: 'center',
    backgroundColor: 'green',
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    flexDirection: 'row'
  },
  textBlock: {
    marginBottom: 10
  }
})

interface ReportPurchaseOrderProps {
  reportPurchaseOrderDto: ReportPurchaseOrderDto
  vendorOrderId: string
}

const TableWithPDFButton = ({
  reportPurchaseOrderDto,
  vendorOrderId
}: ReportPurchaseOrderProps) => {
  let vendorAddress =
    format(
      reportPurchaseOrderDto.vendor?.addresses.find(
        address => address.addressType?.name == 'Physical'
      ),
      formats.address
    ) ?? new AddressDto()
  let dateGenerated = format(moment(), formats.date)
  let opportunityQuote = reportPurchaseOrderDto.opportunity?.quote ?? new QuoteDto()
  let opportunityQuoteRevision = opportunityQuote.quoteRevision ?? new QuoteRevisionDto()
  let opportunityQuoteRevisionLineItem =
    opportunityQuoteRevision.quoteLineItem ?? new QuoteLineItemDto()
  let vendorOrder =
    opportunityQuoteRevisionLineItem.vendorOrders?.find(
      vendorOrder => (vendorOrder.id = vendorOrderId)
    ) ?? new VendorOrderDto()
  let contactPerson =
    vendorOrder.poContactPerson ??
    reportPurchaseOrderDto.opportunity?.salesperson ??
    new PersonDto()
  let scoringArray = [
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring1 &&
      `(1) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring1,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring2 &&
      `(2) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring2,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring3 &&
      `(3) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring3,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring4 &&
      `(4) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring4,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring5 &&
      `(5) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring5,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring6 &&
      `(6) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring6,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring7 &&
      `(7) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring7,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring8 &&
      `(8) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring8,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring9 &&
      `(9) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring9,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring10 &&
      `(10) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring10,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring11 &&
      `(11) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring11,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring12 &&
      `(12) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring12,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring13 &&
      `(13) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring13,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring14 &&
      `(14) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring14,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring15 &&
      `(15) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring15,
        formats.roundedSixteenthsFraction
      )}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring16 &&
      `(16) ${format(
        opportunityQuoteRevisionLineItem.scoringCorrugatorScoring16,
        formats.roundedSixteenthsFraction
      )}`
  ]
  // Only use ".join()" on scoring values that exist
  let scoringString = scoringArray.filter(scoringValue => scoringValue).join(', ')

  return (
    <div>
      <PDFViewer style={{ width: '100%', height: '100%' }}>
        <Document>
          <Page size='LETTER' style={styles.page}>
            <View style={styles.section}>
              {/* Render the information */}
              {/* Use the Image component from @react-pdf/renderer */}
              <Image
                src={process.env.PUBLIC_URL + '/nglogo.jpg'}
                style={{ ...styles.logo }}
              />
              {/* Add the provided code snippet */}

              <Text style={styles.textBlock}>{''}</Text>
              <Text style={styles.textBlock}>
                <Text style={{ ...styles.headerCellGreen }}>Purchase Order</Text>
                {'\n'}
                {vendorOrder.poNumberToVendor}
              </Text>

              <Text style={styles.textBlock}>
                <Text style={{ ...styles.headerCellGreen }}>Date Generated</Text>
                {'\n'}
                {dateGenerated}
              </Text>

              <Text style={styles.textBlock}>
                <Text style={{ ...styles.headerCellGreen }}>Due Date</Text>
                {'\n'}
                {format(vendorOrder.expectedReceivedDate, formats.date)}
              </Text>
              <View style={styles.horizontalLine} />

              <View style={styles.table}>
                <View style={styles.column}>
                  <Text
                    style={{
                      ...styles.headerCellGreen
                    }}
                  >
                    Vendor Name
                  </Text>
                  <Text style={{ ...styles.fullWidthCell }}>
                    {reportPurchaseOrderDto.vendor?.name}
                  </Text>
                  <Text style={{ ...styles.fullWidthCell }}>{vendorAddress}</Text>
                </View>
                <View style={styles.column}>
                  <Text
                    style={{
                      ...styles.headerCellGreen
                    }}
                  >
                    Ship To
                  </Text>
                  <Text style={{ ...styles.fullWidthCell }}>{'JackPack'}</Text>
                  <Text style={{ ...styles.fullWidthCell }}>
                    {'2100 T A Mansour Blvd'}
                  </Text>
                  <Text style={{ ...styles.fullWidthCell }}>{'Flint, MI 48532'}</Text>
                  <Text style={{ ...styles.fullWidthCell }}>{''}</Text>
                </View>
              </View>
              <View style={styles.horizontalLine} />

              <Text style={styles.textBlock}>
                <Text style={{ ...styles.headerCellGreen }}>Instructions</Text>
                {'\n'}
                {vendorOrder.additionalInstructions}
              </Text>

              <View style={{ ...styles.tableGreen }}>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Item Number
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Gross Width
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Gross Length
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Gross SQFT
                </Text>
              </View>
              <View style={styles.table}>
                <Text style={{ ...styles.cell }}>{vendorOrder.vendorItemNumber}</Text>
                <Text style={{ ...styles.cell }}>
                  {format(
                    opportunityQuoteRevisionLineItem.grossWidth,
                    formats.roundedSixteenthsFraction
                  )}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {format(
                    opportunityQuoteRevisionLineItem.grossLength,
                    formats.roundedSixteenthsFraction
                  )}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.grossSquareFeet}
                </Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <View style={{ ...styles.tableGreen }}>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  ECT
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Flute
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                ></Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                ></Text>
              </View>
              <View style={styles.table}>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.ectMullen}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.flute}
                </Text>
                <Text style={{ ...styles.cell }}></Text>
                <Text style={{ ...styles.cell }}></Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <View style={{ ...styles.tableGreen }}>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Vendor Order Quantity
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Ordered MSF
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Cost Per Piece
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Extended Cost
                </Text>
              </View>
              <View style={styles.table}>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.checkValuesVendorOrderQuantity}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.assemblyOrderMsf}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.checkValuesVendorOrderCostPerPiece}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.checkValuesVendorOrderExtendedCost}
                </Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <View style={{ ...styles.tableGreen }}>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Scores
                </Text>
              </View>
              <View style={styles.table}>
                <Text style={{ ...styles.fullWidthCell }}>{scoringString}</Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <View style={{ ...styles.tableGreen }}>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Scoring Cumulative Width
                </Text>
              </View>
              <View style={styles.table}>
                <Text style={{ ...styles.fullWidthCell }}>
                  {format(
                    opportunityQuoteRevisionLineItem.scoringCumulativeWidth,
                    formats.roundedSixteenthsFraction
                  )}
                </Text>
              </View>

              <View style={styles.horizontalLine} />

              <Text style={styles.textBlock}>
                For additional information about this purchase order, please contact:
              </Text>
              <Text style={styles.fullWidthCell}>
                {contactPerson.firstName} {contactPerson.lastName}
              </Text>
              <Text style={styles.fullWidthCell}>{contactPerson.title}</Text>
              <Text style={styles.fullWidthCell}>Email: {contactPerson.email}</Text>
              <Text style={styles.fullWidthCell}>
                Mobile: {contactPerson.mobilePhone}
              </Text>
              <Text style={styles.fullWidthCell}>Office: {contactPerson.workPhone}</Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  )
}

export default TableWithPDFButton
