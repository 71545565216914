import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View // Import the Image component from @react-pdf/renderer
} from '@react-pdf/renderer'
import {
  CompanyDto,
  JobDto,
  OpportunityDto,
  QuoteLineItemDto,
  VendorOrderDto
} from 'dtos'
import JsBarcode from 'jsbarcode'
import moment from 'moment'
import { format, formats } from 'theme'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: process.env.PUBLIC_URL + '/Roboto-Regular.ttf'
    },
    {
      src: process.env.PUBLIC_URL + '/Roboto-Bold.ttf',
      fontWeight: 'bold'
    }
  ]
})

export const reportTravelerStyles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto',
    padding: 50,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  horizontalLine: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    marginTop: 5,
    marginBottom: 5
  },
  spacingBlock: {
    marginBottom: 10
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  topMargin: {
    flexDirection: 'row',
    marginBottom: 100
  },

  cellCenterSmall: {
    flexDirection: 'row',
    fontSize: 16,
    justifyContent: 'space-between',
    textAlign: 'center',
    width: '100%'
  },
  cellCenterMedium: {
    flexDirection: 'row',
    fontSize: 24,
    justifyContent: 'space-between',
    textAlign: 'center',
    width: '100%'
  },
  cellCenterLarge: {
    flexDirection: 'row',
    fontSize: 28,
    justifyContent: 'space-between',
    textAlign: 'center',
    width: '100%'
  },
  cellLeftSmall: {
    flexDirection: 'row',
    fontSize: 16,
    justifyContent: 'space-between',
    textAlign: 'left',
    width: '100%'
  },
  cellLeftMedium: {
    flexDirection: 'row',
    fontSize: 24,
    justifyContent: 'space-between',
    textAlign: 'left',
    width: '100%'
  },
  cellLeftMediumBold: {
    flexDirection: 'row',
    fontSize: 24,
    fontWeight: 'bold',
    justifyContent: 'space-between',
    textAlign: 'left',
    width: '100%'
  },
  cellLeftLarge: {
    flexDirection: 'row',
    fontSize: 28,
    justifyContent: 'space-between',
    textAlign: 'left',
    width: '100%'
  },
  cellLeftExtraLargeBold: {
    flexDirection: 'row',
    fontSize: 36,
    fontWeight: 'bold',
    justifyContent: 'space-between',
    textAlign: 'left',
    width: '100%'
  },
  cellRightSmall: {
    flexDirection: 'row',
    fontSize: 16,
    justifyContent: 'space-between',
    textAlign: 'right',
    width: '100%'
  },
  cellRightMedium: {
    flexDirection: 'row',
    fontSize: 24,
    justifyContent: 'space-between',
    textAlign: 'right',
    width: '100%'
  },
  cellRightLarge: {
    flexDirection: 'row',
    fontSize: 28,
    justifyContent: 'space-between',
    textAlign: 'right',
    width: '100%'
  },
  cellRightExtraLargeBold: {
    flexDirection: 'row',
    fontSize: 36,
    fontWeight: 'bold',
    justifyContent: 'space-between',
    textAlign: 'right',
    width: '100%'
  }
})

export const generateBarcodeImage = (barcodeValue: string) => {
  const barcodeCanvas = document.createElement('canvas')
  // "displayValue: false" causes the number to not display below the barcode
  // "width" is the width, in pixels of 1 bar in the barcode
  JsBarcode(barcodeCanvas, barcodeValue, { displayValue: false, width: 1 })
  return (
    <Image
      src={barcodeCanvas.toDataURL()}
      style={{
        height: 60,
        width: barcodeCanvas.width,
        maxWidth: '100%'
      }}
    />
  )
}

interface ReportTravelerExceptLastPageProps {
  opportunity: OpportunityDto
  vendorOrderId: string
  jobId: string
}

const ReportTravelerExceptLastPage = ({
  opportunity,
  vendorOrderId,
  jobId
}: ReportTravelerExceptLastPageProps) => {
  class PageDto {
    customerName: string = ''
    customerNumber: string = ''
    skuNumber: string = ''
    jobNumber: string = ''
    quantity: string = ''
    bundleNumber: string = ''
    date: string = ''
  }

  let dateGenerated = format(moment(), formats.date)
  let opportunityQuoteRevisionLineItem =
    opportunity.quote?.quoteRevision?.quoteLineItem ?? new QuoteLineItemDto()
  let vendorOrder =
    opportunityQuoteRevisionLineItem?.vendorOrders?.find(
      vendorOrder => vendorOrder.id === vendorOrderId
    ) ?? new VendorOrderDto()
  let job = vendorOrder?.jobs?.find(job => job.id === jobId) ?? new JobDto()
  let company = opportunity.targetCompany ?? new CompanyDto()

  let pages: PageDto[] = []
  for (let i = 1; i < (vendorOrder.projectedBundles ?? 1); i++) {
    pages.push({
      customerName: company.name.toUpperCase(),
      customerNumber: (
        opportunityQuoteRevisionLineItem.customerPartNumber ?? ''
      ).toUpperCase(),
      skuNumber: opportunityQuoteRevisionLineItem.skuNumber ?? '',
      jobNumber: job.jobNumber?.toString() ?? '',
      quantity:
        opportunityQuoteRevisionLineItem.assemblyFinishedGoodUnitQuantityPerBundle?.toString() ??
        '',
      bundleNumber: i.toString(),
      date: dateGenerated
    })
  }

  return (
    <PDFViewer style={{ width: '100%', height: '100%' }}>
      <Document>
        {pages.length > 0 ? (
          pages.map(page => (
            <Page size='LEGAL' style={reportTravelerStyles.page}>
              <View>
                <Text style={reportTravelerStyles.topMargin}>{''}</Text>
              </View>
              <View>
                <View style={reportTravelerStyles.horizontalLine} />
                <Text style={reportTravelerStyles.cellCenterSmall}>FOLD HERE</Text>
                <Text style={reportTravelerStyles.spacingBlock}>
                  <Text style={reportTravelerStyles.cellLeftSmall}>CUSTOMER: </Text>
                  <Text style={reportTravelerStyles.cellLeftMediumBold}>
                    {page.customerName}
                  </Text>
                </Text>
                <View style={reportTravelerStyles.horizontalLine} />
                <Text style={reportTravelerStyles.spacingBlock}>
                  <Text style={reportTravelerStyles.cellLeftSmall}>CUSTOMER #: </Text>
                  <Text style={reportTravelerStyles.cellLeftMediumBold}>
                    {page.customerNumber}
                  </Text>
                </Text>
                <Text style={reportTravelerStyles.spacingBlock}>{''}</Text>
                <Text style={reportTravelerStyles.cellLeftMedium}>PART #:</Text>
                {generateBarcodeImage(page.skuNumber)}
                <Text style={reportTravelerStyles.cellLeftExtraLargeBold}>
                  {page.skuNumber}
                </Text>
                <Text style={reportTravelerStyles.cellRightMedium}>JOB #:</Text>
                <View style={{ flexDirection: 'row-reverse' }}>
                  {generateBarcodeImage(page.jobNumber)}
                </View>
                <Text style={reportTravelerStyles.cellRightExtraLargeBold}>
                  {page.jobNumber}
                </Text>
                <Text style={reportTravelerStyles.spacingBlock}>{''}</Text>
                <View style={reportTravelerStyles.row}>
                  <Text style={reportTravelerStyles.cellLeftMedium}>QUANTITY:</Text>
                  <Text style={reportTravelerStyles.cellRightMedium}>BUNDLE #:</Text>
                </View>
                <View style={reportTravelerStyles.row}>
                  {generateBarcodeImage(page.quantity)}
                  {generateBarcodeImage(page.bundleNumber)}
                </View>
                <View style={reportTravelerStyles.row}>
                  <Text style={reportTravelerStyles.cellLeftExtraLargeBold}>
                    {page.quantity}
                  </Text>
                  <Text style={reportTravelerStyles.cellRightExtraLargeBold}>
                    {page.bundleNumber}
                  </Text>
                </View>
                <Text style={reportTravelerStyles.spacingBlock}>{''}</Text>
                <Text style={reportTravelerStyles.cellLeftSmall}>
                  DATE: {format(page.date, formats.date)}
                </Text>
              </View>
            </Page>
          ))
        ) : (
          <Page>
            <Text style={reportTravelerStyles.spacingBlock}>{''}</Text>
            <Text style={reportTravelerStyles.spacingBlock}>{''}</Text>
            <View style={reportTravelerStyles.horizontalLine} />
            <Text style={reportTravelerStyles.cellCenterLarge}>
              NO TRAVELERS TO PRINT
            </Text>
            <Text style={reportTravelerStyles.cellCenterMedium}>
              This pallet only has 1 bundle, so the traveler must be printed via the To
              Dos STOP/COMPLETE dialog
            </Text>
          </Page>
        )}
      </Document>
    </PDFViewer>
  )
}

export default ReportTravelerExceptLastPage
