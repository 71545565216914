import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  autocompleteClasses,
  Button,
  Popper,
  TextField,
  Typography
} from '@mui/material'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import { styled } from '@mui/material/styles'
import * as api from 'api'
import RawInventoryReconciliationDialog from 'components/RawInventoryReconciliationDialog'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { useAuthContext } from 'context'
import {
  PersonDto,
  RawInventoryDto,
  VendorOrderDto,
  vendorOrderValidationSchema
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { useSnackbar } from 'notistack'
import { ReportPurchaseOrder } from 'pages'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useNavigate, useParams } from 'react-router-dom'
import { format, formats } from 'theme'
import { errorHandling } from '../constants'
import { ListboxComponentPerson, StyledPopper } from 'components'
import { useDebounce } from 'utils'

export default function VendorOrderDetails() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams()
  const { CAN_RECONCILE_RAW_INVENTORY } = useAuthContext()

  const [isCompanyPeopleLoading, setIsCompanyPeopleLoading] = useState<boolean>(false)
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false)
  const [isVendorOrderLoading, setIsVendorOrderLoading] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<VendorOrderDto>(new VendorOrderDto())
  const [people, setPeople] = useState<PersonDto[]>([])
  const [rawInventory, setRawInventory] = useState<RawInventoryDto>(new RawInventoryDto())
  const [rawInventoryReconciliationDialogOpen, setRawInventoryReconciliationDialogOpen] =
    useState<boolean>(false)

  const isLoading = isCompanyPeopleLoading || isVendorOrderLoading || isReportLoading

  useEffect(() => {
    getJackpackCompanyPeople()
  }, [])

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      getVendorOrder(id)
    }
  }, [id])

  const getJackpackCompanyPeople = useDebounce(() => {
    setIsCompanyPeopleLoading(true)
    api
      .getPersonCompanyPeopleForJackPack()
      .then(res => {
        setPeople(res.value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsCompanyPeopleLoading(false)
      })
  }, 300)

  const getVendorOrder = (vendorOrderId: string) => {
    setIsVendorOrderLoading(true)
    api
      .getVendorOrderById(vendorOrderId)
      .then(({ value }) => {
        setInitialValues(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsVendorOrderLoading(false)
      })
  }

  const onClickReadyToSchedule = () => {
    if (
      window.confirm(
        'Are you sure this is ready to schedule? If you click "OK", a Job will be created and you will not be able to receive any more inventory for this Vendor Order.'
      )
    ) {
      if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
        api
          .readyToSchedule(id)
          .then(({ value }) => {
            setInitialValues(value)
            enqueueSnackbar(
              'Job Created Successfully. Inventory Can No Longer Be Received For This Vendor Order.',
              { variant: 'success' }
            )
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsVendorOrderLoading(false)
          })
      }
    } else {
      enqueueSnackbar('Vendor Order Was Not Marked Ready To Schdule', { variant: 'info' })
    }
  }

  const openPurchaseOrderReportWindow = () => {
    setIsReportLoading(true)
    api
      .getPurchaseOrderReport(id ?? '')
      .then(response => {
        const newWindow = window.open('', '_blank', 'width=800,height=600')

        if (newWindow) {
          newWindow.document.body.innerHTML =
            '<div id="purchase-order-report-container"></div>'

          const root = createRoot(
            newWindow.document.getElementById(
              'purchase-order-report-container'
            ) as HTMLElement
          )

          root.render(
            <ReportPurchaseOrder
              reportPurchaseOrderDto={response.value}
              vendorOrderId={id ?? ''}
            />
          )
        } else {
          console.error('Failed to open a new window.')
        }
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsReportLoading(false)
      })
  }

  return (
    <Layout title='Vendor Order Details' isLoading={isLoading}>
      <RawInventoryReconciliationDialog
        rawInventory={rawInventory}
        vendorOrder={initialValues}
        open={rawInventoryReconciliationDialogOpen}
        onClose={() => {
          setRawInventory(new RawInventoryDto())
          setRawInventoryReconciliationDialogOpen(false)
        }}
        onSave={rawInventoryAdjustment => {
          setIsVendorOrderLoading(true)
          return api
            .createRawInventoryReconciliationAdjustment(rawInventoryAdjustment)
            .then(({ value }) => {
              enqueueSnackbar(`Raw Inventory Reconciliation Added Successfully!`, {
                variant: 'success'
              })
              getVendorOrder(id ?? '')
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setRawInventory(new RawInventoryDto())
              setIsVendorOrderLoading(false)
              setRawInventoryReconciliationDialogOpen(false)
            })
        }}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={vendorOrderValidationSchema}
        onSubmit={(values, formikHelpers) => {
          setIsVendorOrderLoading(true)
          api
            .updateVendorOrder(values)
            .then(({ value }) => {
              enqueueSnackbar('Vendor Order Has Been Saved!', { variant: 'success' })
              setInitialValues(value)
              formikHelpers.resetForm({ values: value })
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              formikHelpers.setSubmitting(false)
              setIsVendorOrderLoading(false)
            })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <Details
              header={<DetailsHeaderCard title='Vendor Order Details' />}
              tabs={[{ value: 'tab1', label: 'Details' }]}
              onSubmit={handleSubmit}
            >
              <DetailsTab value='tab1'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.vendorItemNumber && errors.vendorItemNumber
                            )}
                            fullWidth
                            helperText={
                              touched.vendorItemNumber && errors.vendorItemNumber
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  {values?.quoteLineItem?.specGeneratedOrProvidedBy ===
                                  'Spec Generated'
                                    ? 'CS'
                                    : 'CJ'}
                                </InputAdornment>
                              )
                            }}
                            label='Vendor Item Number'
                            name='vendorItemNumber'
                            onBlur={handleBlur}
                            // This field is the only place that manages prepending input adornment, the back-end doesn't touch the prefix
                            onChange={e => {
                              setFieldValue(
                                'vendorItemNumber',
                                (values?.quoteLineItem?.specGeneratedOrProvidedBy ===
                                'Spec Generated'
                                  ? 'CS'
                                  : 'CJ') + e.target.value
                              )
                            }}
                            // The field value here needs to trim off the input adornment prefix since it is already shown and prepended in the input adornment itself
                            value={values.vendorItemNumber?.substring(2) || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.poNumberToVendor && errors.poNumberToVendor
                            )}
                            fullWidth
                            helperText={
                              touched.poNumberToVendor && errors.poNumberToVendor
                            }
                            label='PO Number to Vendor'
                            name='poNumberToVendor'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.poNumberToVendor || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disabled={isSubmitting}
                            id='poContactPerson'
                            disableListWrap
                            getOptionLabel={option =>
                              option.firstName + ' ' + option.lastName
                            }
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value.id
                            }}
                            ListboxComponent={ListboxComponentPerson}
                            onChange={(_e, value) => {
                              setFieldValue('poContactPerson', value)
                            }}
                            options={people}
                            PopperComponent={StyledPopper}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label='PO Contact'
                                error={Boolean(
                                  touched.poContactPerson && errors.poContactPerson
                                )}
                                helperText={
                                  touched.poContactPerson && errors.poContactPerson
                                }
                              />
                            )}
                            renderOption={(props, option, state) =>
                              [props, option, state.index] as React.ReactNode
                            }
                            // The Autocomplete is only blank when the value is null, NOT undefined, so this must be "values.salesperson ?? null"
                            value={values.poContactPerson ?? null}
                          />
                        </Grid>

                        <Grid
                          item
                          container
                          spacing={2}
                          alignItems='center'
                          justifyContent='center'
                        >
                          <Grid item>
                            <Button
                              variant='outlined'
                              disabled={
                                initialValues.vendorItemNumber !==
                                  values.vendorItemNumber ||
                                initialValues.poNumberToVendor !==
                                  values.poNumberToVendor ||
                                !(
                                  initialValues.vendorItemNumber &&
                                  initialValues.poNumberToVendor
                                )
                              }
                              onClick={() => {
                                openPurchaseOrderReportWindow()
                              }}
                            >
                              Download Vendor PO
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={
                              isSubmitting ||
                              !(
                                initialValues.vendorItemNumber &&
                                initialValues.poNumberToVendor
                              )
                            }
                            error={Boolean(touched.dateOrdered && errors.dateOrdered)}
                            fullWidth
                            helperText={touched.dateOrdered && errors.dateOrdered}
                            InputLabelProps={{ shrink: true }}
                            label='Date Ordered'
                            name='dateOrdered'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='date'
                            value={
                              values?.dateOrdered
                                ? format(values.dateOrdered, formats.dateOnlyField)
                                : ''
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={
                              isSubmitting ||
                              !(
                                initialValues.vendorItemNumber &&
                                initialValues.poNumberToVendor
                              )
                            }
                            error={Boolean(
                              touched.vendorOrderNumber && errors.vendorOrderNumber
                            )}
                            fullWidth
                            helperText={
                              touched.vendorOrderNumber && errors.vendorOrderNumber
                            }
                            label='Vendor Order Number'
                            name='vendorOrderNumber'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.vendorOrderNumber || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={
                              isSubmitting ||
                              !(
                                initialValues.vendorItemNumber &&
                                initialValues.poNumberToVendor
                              )
                            }
                            error={Boolean(
                              touched.expectedReceivedDate && errors.expectedReceivedDate
                            )}
                            fullWidth
                            helperText={
                              touched.expectedReceivedDate && errors.expectedReceivedDate
                            }
                            InputLabelProps={{ shrink: true }}
                            label='Expected Receive Date'
                            name='expectedReceivedDate'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='date'
                            value={
                              values?.expectedReceivedDate
                                ? format(
                                    values.expectedReceivedDate,
                                    formats.dateOnlyField
                                  )
                                : ''
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            SKU#: {values?.quoteLineItem?.skuNumber}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Quotation Ref#: {values?.quoteLineItem?.quotationRefNumber}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.additionalInstructions &&
                                errors.additionalInstructions
                            )}
                            fullWidth
                            helperText={
                              touched.additionalInstructions &&
                              errors.additionalInstructions
                            }
                            label='Additional Instructions'
                            name='additionalInstructions'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.additionalInstructions || ''}
                            maxRows={4}
                            minRows={4}
                            multiline
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Quote Line Item Notes: {values.quoteLineItem?.lineItemNotes}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Receiving Information {/*History*/}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Vendor Order Quantity
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Received To Date
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Still Needed
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            {values.quoteLineItem?.checkValuesVendorOrderQuantity}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            {values.receivedToDate}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            {values.quoteLineItem?.checkValuesVendorOrderQuantity! -
                              (values?.receivedToDate ?? 0)}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        spacing={2}
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item sx={{ mt: 2 }}>
                          <Button
                            variant='outlined'
                            onClick={() => {
                              onClickReadyToSchedule()
                            }}
                            disabled={
                              initialValues.vendorOrderStatus?.specialIdentifier !==
                              'PARTIAL_RECEIVED'
                            }
                          >
                            Ready to Schedule
                          </Button>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Received
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Pallet #
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Quantity
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Received Date
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3} />
                        </Grid>
                        {values.rawInventory?.map((rawInventoryItem, index) => (
                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {rawInventoryItem.palletNumber}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {rawInventoryItem.quantityReceived}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {format(
                                  rawInventoryItem.createdDateTimeUtc,
                                  formats.dateTime
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Button
                                variant='outlined'
                                onClick={() => {
                                  setRawInventory(rawInventoryItem)
                                  setRawInventoryReconciliationDialogOpen(true)
                                }}
                                disabled={
                                  !CAN_RECONCILE_RAW_INVENTORY ||
                                  values.vendorOrderStatus?.specialIdentifier ==
                                    'RECEIVED_IN_FULL'
                                }
                              >
                                Reconcile
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#2780E3', fontWeight: 600 }}
                          >
                            Order Information
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Customer Part Number:{' '}
                            {values.quoteLineItem?.customerPartNumber}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Contract Duration (Months):{' '}
                            {values.quoteLineItem?.contractDuration}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Customer Order Quantity:{' '}
                            {values.quoteLineItem?.assemblyCustomerOrderQuantity}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Vendor Order Quantity:{' '}
                            {values?.quoteLineItem?.checkValuesVendorOrderQuantity}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Finished Good Unit Quantity Per Bundle:{' '}
                            {
                              values.quoteLineItem
                                ?.assemblyFinishedGoodUnitQuantityPerBundle
                            }
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Outs: {values.quoteLineItem?.assemblyOuts}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Test Run Quantity (Pieces):{' '}
                            {values.quoteLineItem?.testRunQuantity}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Minimum Order Run Quantity (Pieces):{' '}
                            {values.quoteLineItem?.minimumOrderRunQuantity}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Finished Goods Projection: {values?.finishedGoodsProjection}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Projected Bundles: {values?.projectedBundles}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Job Center: {values.quoteLineItem?.jobCenter}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      Vendor Item Number and PO Number to Vendor are required, assigned by
                      NorthGate, and provided to vendor to be on the vendor shipping label
                      for Receiving to scan.||The Vendor Item Number prefix is "CS" if the
                      customer is an internal customer, otherwise the prefix is "CJ"
                      (E.g., CS39856PAD).||If the user attempts to save a Vendor Order
                      with a Vendor Order Number and Vendor Item Number combination that
                      is already in use on another Vendor Order, a warning snackbar will
                      be displayed.||The Download Vendor PO button and Date Ordered,
                      Vendor Order Number, and Expected Receive Date fields are disabled
                      until Vendor Item Number and PO Number to Vendor are filled in and
                      the form is saved.||After Vendor Item Number and PO Number to Vendor
                      are filled in and the form is saved, clicking the Download Vendor PO
                      button downloads the Vendor PO to send to the vendor outside of the
                      software and Date Ordered, Vendor Order Number, and Expected Receive
                      Date must either all be filled out or all be empty to save.||If
                      Vendor Item Number and/or PO Number to Vendor are changed, the
                      Download Vendor PO button is disabled until the record is
                      saved.||Date Ordered defaults to empty and must be filled in with
                      the date ordered.||Vendor Order Number is assigned by the vendor to
                      be on the vendor shipping label for Receiving to scan.||The SKU# is
                      populated from the SKU# on the approved quote.||When Vendor Item
                      Number, PO Number to Vendor, Date Ordered, Vendor Order Number, and
                      Expected Receive Date are filled in and the form is saved, the
                      vendor order status is set to "Ordered".||After the vendor order
                      status is set to "Ordered" inventory can be received on the
                      Receiving page.||When inventory is received on the Receiving page,
                      the vendor order status is set to "Partial Received".||The Ready to
                      Schedule button is disabled if the vendor order status is not set to
                      "Partial Received".||When the user clicks the Ready to Schedule
                      button, a pop-up will appear asking, "Are you sure this is ready to
                      schedule? If you click "OK", a Job will be created and you will not
                      be able to receive any more inventory for this Vendor Order.".
                      Clicking "OK" sets the vendor order status to "Received in Full"
                      indicating that all inventory for the vendor order has been
                      received, creates a job, and disallows receiving or reconciling any
                      more inventory for the Vendor Order (We can't wait until an exact
                      number is received as there could be small shortages, damaged
                      materials, etc). Clicking "Cancel" closes the pop-up.||Receiving
                      Information shows all received quantities for this order.||Receiving
                      shows the individual pallets received for this order.||When the user
                      clicks the Reconcile button in the row of a received pallet, the
                      "Reconcile Raw Inventory" dialog opens.||The Order Information
                      Finished Goods Projection value is calculated as (Received to Date *
                      Outs).|| The Order Information Projected Bundles value is calculated
                      as (Finished Goods Projection / Finished Goods Unit Quantity Per
                      Bundle), rounded up.||The Vendor Order status options are: Need to
                      Order, Ordered, Partial Received, or Received in Full.
                    </ExplanationAccordion>
                    {/* <ExplanationAccordion development></ExplanationAccordion> */}
                  </Grid>
                </Grid>
              </DetailsTab>

              <DetailsActions>
                <Button
                  color='secondary'
                  onClick={() => {
                    navigate('/vendor-orders')
                  }}
                  variant='text'
                >
                  BACK
                </Button>

                <LoadingButton
                  color='primary'
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </LoadingButton>
              </DetailsActions>
            </Details>
          )
        }}
      </Formik>
    </Layout>
  )
}
