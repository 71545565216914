import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxDateMessage,
  maxDateValue,
  maxLargeIntMessage,
  maxLargeIntValue,
  maxString2000Message,
  maxString2000Value,
  maxString200Message,
  maxString200Value,
  minDateMessage,
  minDateValue,
  minIntMessage,
  minIntValue,
  requiredFieldMessage
} from '.././constants'
import { CompanyDto } from './companies'
import { JobDto } from './job'
import { QuoteLineItemDto } from './quote'
import { RawInventoryDto } from './rawInventory'
import { VendorOrderStatusDto } from './vendorOrderStatus'
import { FinishedGoodItemTypeDto } from './finishedGoodItemTypes'
import { PersonDto } from './people'

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class VendorOrderDto {
  id?: string = undefined
  vendorItemNumber?: string = undefined
  poNumberToVendor?: string = undefined
  // Because this does not have a time, DateOnly is used on the back-end. DateOnly and TimeOnly types must be treated as a string on the front-end
  dateOrdered?: string = undefined
  vendorOrderNumber?: string = undefined
  // Because this does not have a time, DateOnly is used on the back-end. DateOnly and TimeOnly types must be treated as a string on the front-end
  expectedReceivedDate?: string = undefined
  additionalInstructions?: string = undefined
  receivedToDate?: number = undefined
  finishedGoodsProjection?: number = undefined
  projectedBundles?: number = undefined
  sheetItemDescription?: string = undefined
  sheetGenericDescription?: string = undefined
  isActive?: boolean = true

  poContactPerson?: PersonDto = undefined
  sheetItemType?: FinishedGoodItemTypeDto = undefined
  company?: CompanyDto = undefined
  vendorOrderStatus?: VendorOrderStatusDto = undefined
  quoteLineItem?: QuoteLineItemDto = undefined
  readonly_isVendorReorder: boolean = false
  rawInventory?: RawInventoryDto[] = []
  jobs?: JobDto[] = []
}

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class VendorReorderDto {
  vendorOrderId?: string = undefined
  assemblyCustomerOrderQuantity?: number = undefined
  quoteValidUntilDate?: string = undefined
}

export const vendorOrderValidationSchema = yup.object<VendorOrderDto>({
  id: yup.string().nullable(),
  vendorItemNumber: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  poNumberToVendor: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  poContactPerson: yup.object().required(requiredFieldMessage),
  dateOrdered: yup.string().nullable(),
  vendorOrderNumber: yup.string().max(maxString200Value, maxString200Message).nullable(),
  expectedReceivedDate: yup.string().nullable(),
  additionalInstructions: yup
    .string()
    .max(maxString2000Value, maxString2000Message)
    .nullable()
})

export const vendorReorderValidationSchema = yup.object<VendorReorderDto>({
  quoteValidUntilDate: yup
    .date()
    .typeError(fieldTypeMessage('Date'))
    .min(minDateValue, minDateMessage)
    .max(maxDateValue, maxDateMessage)
    .required(requiredFieldMessage),
  assemblyCustomerOrderQuantity: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxLargeIntValue, maxLargeIntMessage)
    .required(requiredFieldMessage)
})
