import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField
} from '@mui/material'
import { ExplanationAccordion } from 'components/_template/accordion'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import {
  ForkliftPalletLocationDto,
  forkliftToDoCheckMoveFinishedGoodsInventoryValidationSchema,
  ForkliftToDoMoveFinishedGoodsInventoryDto
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { useRef } from 'react'

interface ForkliftToDosCheckMoveFinishedGoodsDialogProps {
  forkliftPalletLocations: ForkliftPalletLocationDto[]
  forkliftToDo: ForkliftToDoMoveFinishedGoodsInventoryDto
  isLoading: boolean
  onClose: () => void
  open: boolean
  onSave: (
    values: ForkliftToDoMoveFinishedGoodsInventoryDto
  ) => Promise<void | ForkliftToDoMoveFinishedGoodsInventoryDto>
}

export default function ForkliftToDosCheckMoveFinishedGoodsDialog({
  forkliftPalletLocations,
  forkliftToDo,
  isLoading,
  onClose,
  open,
  onSave
}: ForkliftToDosCheckMoveFinishedGoodsDialogProps) {
  // These refs need to be cast to type any because TypeScript doesn't like them being initialized to null
  const skuNumberRef: any = useRef(null)
  const jobNumberRef: any = useRef(null)
  const bundleNumberRef: any = useRef(null)
  const dropOffForkliftPalletLocationRef: any = useRef(null)

  const onFocusSkuNumber = () => {
    skuNumberRef.current.focus()
  }
  const onFocusJobNumber = () => {
    jobNumberRef.current.focus()
  }
  const onFocusBundleNumber = () => {
    bundleNumberRef.current.focus()
  }
  const onFocusDropOffForkliftPalletLocation = () => {
    dropOffForkliftPalletLocationRef.current.focus()
  }

  return (
    // disableRestoreFocus seems necessary for the autofocus prop to function. See https://github.com/mui/material-ui/issues/33004#issuecomment-1455260156
    <Dialog open={open} disableRestoreFocus>
      <Formik
        enableReinitialize
        initialValues={forkliftToDo || new ForkliftToDoMoveFinishedGoodsInventoryDto()}
        validateOnBlur
        validateOnChange
        validationSchema={forkliftToDoCheckMoveFinishedGoodsInventoryValidationSchema}
        onSubmit={(values, formikHelpers) => {
          onSave(values).finally(() => {
            onFocusSkuNumber()
          })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          const onSubmitForm = () => {
            // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
            // Otherwise, sometimes the field errors won't display even though the validation throws them
            validateForm().then(errors => {
              const errorKeys = Object.keys(errors)
              if (errorKeys.length === 0) {
                submitForm()
              } else {
                setTouched(setNestedObjectValues(errors, true))
                showFormErrorsPrompt(errors)
              }
            })
          }

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>
                    Scan Barcodes (or key in values) for each bundle
                  </DialogTitle>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading}
                      autoFocus
                      error={Boolean(touched.skuNumber && errors.skuNumber)}
                      fullWidth
                      helperText={touched.skuNumber && errors.skuNumber}
                      label='SKU #'
                      name='skuNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.skuNumber || ''}
                      inputRef={skuNumberRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 1 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={isLoading}
                          checked={!values.isAdditionalInformationAvailable}
                          name='isAdditionalInformationAvailable'
                          onBlur={handleBlur}
                          onChange={() => {
                            setFieldValue(
                              'isAdditionalInformationAvailable',
                              !values.isAdditionalInformationAvailable
                            )
                          }}
                          value={!values.isAdditionalInformationAvailable}
                          size='small'
                        />
                      }
                      label='Additional Information Not Available'
                    />
                  </Grid>

                  {values.isAdditionalInformationAvailable ? (
                    <Grid item xs={12}>
                      <TextField
                        disabled={isLoading}
                        error={Boolean(touched.jobNumber && errors.jobNumber)}
                        fullWidth
                        helperText={touched.jobNumber && errors.jobNumber}
                        label='Job #'
                        name='jobNumber'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.jobNumber || ''}
                        inputRef={jobNumberRef}
                        onKeyUp={e => {
                          if (e.key === 'Enter') {
                            onSubmitForm()
                          }
                        }}
                        inputProps={{ tabIndex: 2 }}
                      />
                    </Grid>
                  ) : null}

                  {values.isAdditionalInformationAvailable ? (
                    <Grid item xs={12}>
                      <TextField
                        disabled={isLoading}
                        error={Boolean(touched.bundleNumber && errors.bundleNumber)}
                        fullWidth
                        helperText={touched.bundleNumber && errors.bundleNumber}
                        label='Bundle #'
                        name='bundleNumber'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bundleNumber || ''}
                        inputRef={bundleNumberRef}
                        onKeyUp={e => {
                          if (e.key === 'Enter') {
                            onSubmitForm()
                          }
                        }}
                        inputProps={{ tabIndex: 3 }}
                      />
                    </Grid>
                  ) : null}

                  {values.isAdditionalInformationAvailable ? (
                    <Grid item xs={12}>
                      <TextField
                        disabled={isLoading}
                        error={Boolean(
                          touched.dropOffForkliftPalletLocation &&
                            errors.dropOffForkliftPalletLocation
                        )}
                        fullWidth
                        helperText={
                          touched.dropOffForkliftPalletLocation &&
                          errors.dropOffForkliftPalletLocation
                        }
                        label='Drop Off Location'
                        name='dropOffForkliftPalletLocation'
                        onBlur={handleBlur}
                        onChange={e => {
                          setFieldValue(
                            'dropOffForkliftPalletLocation',
                            JSON.parse(e.target.value)
                          )
                        }}
                        select
                        value={
                          values?.dropOffForkliftPalletLocation
                            ? JSON.stringify(
                                forkliftPalletLocations?.filter(
                                  forkliftPalletLocation =>
                                    forkliftPalletLocation.id ===
                                    values.dropOffForkliftPalletLocation!.id
                                )[0]
                              )
                            : ''
                        }
                        inputRef={dropOffForkliftPalletLocationRef}
                        onKeyUp={e => {
                          if (e.key === 'Enter') {
                            onSubmitForm()
                          }
                        }}
                        inputProps={{ tabIndex: 3 }}
                      >
                        {forkliftPalletLocations
                          ?.filter(
                            forkliftPalletLocation =>
                              // Per Derek Nov-22-2024 ForkliftPalletLocations with a special identifer beginning with "WH_" are warehousing locations
                              // RawInventory can only be moved via this dialog to and from warehousing locations
                              forkliftPalletLocation.specialIdentifier?.substring(0, 3) ==
                              'WH_'
                          )
                          ?.map(forkliftPalletLocation => (
                            <MenuItem
                              key={forkliftPalletLocation.id}
                              value={JSON.stringify(forkliftPalletLocation)}
                            >
                              {forkliftPalletLocation.description}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      All fields are required.||If additional information is available the
                      SKU #, Job #, and Bundle # combination must match an existing FG
                      inventory bundle.||To add Finished Goods to the inventory that do
                      not currently exist, enter only the SKU # and click the "Additional
                      Information Not Available" check box.|| When "Additional Information
                      Not Available" is checked, if the entered SKU # exists an error will
                      be thrown, otherwise, an Opportunity, Quote, Quote Revision, Quote
                      Line Item, Vendor Order, and Job will be created with all values set
                      to default values and a Finished Goods Inventory bundle is created
                      with the entered SKU #, quantity, and location.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button
                  disabled={isLoading}
                  color='secondary'
                  onClick={() => {
                    onClose()
                  }}
                  tabIndex={6}
                  variant='text'
                >
                  CLOSE
                </Button>

                <Button
                  disabled={isLoading}
                  onClick={() => {
                    onSubmitForm()
                  }}
                  tabIndex={5}
                  variant='contained'
                >
                  SUBMIT
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
