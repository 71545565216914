import { LoadingButton } from '@mui/lab'
import { Button, Divider, TextField, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import * as api from 'api'
import FinishedGoodsInventoryReconciliationDialog from 'components/FinishedGoodsInventoryReconciliationDialog'
import FinishedGoodsInventoryScrapDialog from 'components/FinishedGoodsInventoryScrapDialog'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { useAuthContext } from 'context'
import {
  FinishedGoodItemTypeDto,
  FinishedGoodsInventoryDto,
  JobDto,
  finishedGoodsValidationSchema
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { format, formats } from 'theme'
import { errorHandling } from '../constants'
import { DecimalFormat } from 'components'

export default function FinishedGoodInventoryDetails() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { CAN_RECONCILE_FINISHED_GOODS_INVENTORY, CAN_SCRAP_FINISHED_GOODS_INVENTORY } =
    useAuthContext()

  const [initialValues, setInitialValues] = useState<JobDto>(new JobDto())
  const [isGettingFinishedGoodItemTypes, setIsGettingFinishedGoodItemTypes] =
    useState<boolean>(false)
  const [isGettingFinishedGoodsInventory, setIsGettingFinishedGoodsInventory] =
    useState<boolean>(false)
  const [isSavingFinishedGoodsInventory, setIsSavingFinishedGoodsInventory] =
    useState<boolean>(false)
  const [
    isSavingFinishedGoodsInventoryReconciliation,
    setIsSavingFinishedGoodsInventoryReconciliation
  ] = useState<boolean>(false)
  const [isSavingFinishedGoodsInventoryScrap, setIsSavingFinishedGoodsInventoryScrap] =
    useState<boolean>(false)
  const [finishedGoodItemTypesList, setFinishedGoodItemTypesList] = useState<
    FinishedGoodItemTypeDto[]
  >([])
  const [finishedGoodsInventory, setFinishedGoodsInventory] =
    useState<FinishedGoodsInventoryDto>(new FinishedGoodsInventoryDto())
  const [
    finishedGoodsInventoryReconciliationDialogOpen,
    setFinishedGoodsInventoryReconciliationDialogOpen
  ] = useState<boolean>(false)
  const [
    finishedGoodsInventoryScrapDialogOpen,
    setFinishedGoodsInventoryScrapDialogOpen
  ] = useState<boolean>(false)

  const isLoading =
    isGettingFinishedGoodItemTypes ||
    isGettingFinishedGoodsInventory ||
    isSavingFinishedGoodsInventory ||
    isSavingFinishedGoodsInventoryReconciliation ||
    isSavingFinishedGoodsInventoryScrap

  useEffect(() => {
    getFinishedGoodItemTypes()
  }, [])

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      getFinishedGoodsInventory(id)
    }
  }, [id])

  const getFinishedGoodItemTypes = () => {
    setIsGettingFinishedGoodItemTypes(true)
    api
      .getFinishedGoodItemTypes()
      .then(({ value }) => {
        setFinishedGoodItemTypesList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingFinishedGoodItemTypes(false)
      })
  }

  const getFinishedGoodsInventory = (jobId: string) => {
    setIsGettingFinishedGoodsInventory(true)
    api
      .getFinishedGoodsInventoryByJobId(jobId)
      .then(({ value }) => {
        // This form uses the JobDto because it only modifies metadata present on the job, not the FinishedGoodsInventoryDto
        setInitialValues(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingFinishedGoodsInventory(false)
      })
  }

  return (
    <Layout title='Finished Good Inventory Details' isLoading={isLoading}>
      <FinishedGoodsInventoryScrapDialog
        finishedGoodsInventory={finishedGoodsInventory}
        isLoading={isLoading}
        job={initialValues}
        open={finishedGoodsInventoryScrapDialogOpen}
        onClose={() => {
          setFinishedGoodsInventory(new FinishedGoodsInventoryDto())
          setFinishedGoodsInventoryScrapDialogOpen(false)
        }}
        onSave={finishedGoodsInventoryAdjustment => {
          setIsSavingFinishedGoodsInventoryScrap(true)
          return api
            .createFinishedGoodsInventoryScrapAdjustment(finishedGoodsInventoryAdjustment)
            .then(({ value }) => {
              enqueueSnackbar(`Finished Goods Inventory Scrap Added Successfully!`, {
                variant: 'success'
              })
              getFinishedGoodsInventory(id ?? '')
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setFinishedGoodsInventory(new FinishedGoodsInventoryDto())
              setIsSavingFinishedGoodsInventoryScrap(false)
              setFinishedGoodsInventoryScrapDialogOpen(false)
            })
        }}
      />
      <FinishedGoodsInventoryReconciliationDialog
        finishedGoodsInventory={finishedGoodsInventory}
        isLoading={isLoading}
        job={initialValues}
        open={finishedGoodsInventoryReconciliationDialogOpen}
        onClose={() => {
          setFinishedGoodsInventory(new FinishedGoodsInventoryDto())
          setFinishedGoodsInventoryReconciliationDialogOpen(false)
        }}
        onSave={finishedGoodsInventoryAdjustment => {
          setIsSavingFinishedGoodsInventoryReconciliation(true)
          return api
            .createFinishedGoodsInventoryReconciliationAdjustment(
              finishedGoodsInventoryAdjustment
            )
            .then(({ value }) => {
              enqueueSnackbar(
                `Finished Goods Inventory Reconciliation Added Successfully!`,
                {
                  variant: 'success'
                }
              )
              getFinishedGoodsInventory(id ?? '')
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setFinishedGoodsInventory(new FinishedGoodsInventoryDto())
              setIsSavingFinishedGoodsInventoryReconciliation(false)
              setFinishedGoodsInventoryReconciliationDialogOpen(false)
            })
        }}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={finishedGoodsValidationSchema}
        onSubmit={async (values, submitProps) => {
          setIsSavingFinishedGoodsInventory(true)
          await api
            .updateFinishedGoodsInventory(values)
            .then(({ value }) => {
              enqueueSnackbar('Finished Goods Inventory Has Been Saved!', {
                variant: 'success'
              })

              // This form uses the JobDto because it only modifies metadata present on the job, not the FinishedGoodsInventoryDto
              setInitialValues(value)
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setIsSavingFinishedGoodsInventory(false)
            })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <Details
              header={
                <DetailsHeaderCard
                  title={`Finished Good Details - ${values.vendorOrder?.company?.name} - #${values.jobNumber}`}
                />
              }
              tabs={[
                { value: 'tab1', label: 'Details' },
                { value: 'tab2', label: 'Bundles' }
              ]}
              onSubmit={e => e.preventDefault()}
            >
              <DetailsTab value='tab1'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Finished Good
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            SKU#: {format(values?.vendorOrder?.quoteLineItem?.skuNumber)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.finishedGoodItemDescription &&
                                errors.finishedGoodItemDescription
                            )}
                            fullWidth
                            helperText={
                              touched.finishedGoodItemDescription &&
                              errors.finishedGoodItemDescription
                            }
                            label='Item Description'
                            name='finishedGoodItemDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.finishedGoodItemDescription || ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.finishedGoodGenericDescription &&
                                errors.finishedGoodGenericDescription
                            )}
                            fullWidth
                            helperText={
                              touched.finishedGoodGenericDescription &&
                              errors.finishedGoodGenericDescription
                            }
                            label='Generic Description'
                            name='finishedGoodGenericDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.finishedGoodGenericDescription || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              disabled={isSubmitting}
                              error={Boolean(
                                touched.finishedGoodItemType &&
                                  errors.finishedGoodItemType
                              )}
                              fullWidth
                              helperText={
                                touched.finishedGoodItemType &&
                                errors.finishedGoodItemType
                              }
                              label='Item Type'
                              name='finishedGoodItemType'
                              onBlur={handleBlur}
                              onChange={e => {
                                setFieldValue(
                                  'finishedGoodItemType',
                                  JSON.parse(e.target.value)
                                )
                              }}
                              select
                              value={
                                values?.finishedGoodItemType
                                  ? JSON.stringify(
                                      finishedGoodItemTypesList.filter(
                                        finishedGoodItemType =>
                                          finishedGoodItemType?.id ===
                                          values?.finishedGoodItemType?.id
                                      )[0]
                                    )
                                  : ''
                              }
                            >
                              {finishedGoodItemTypesList.map(finishedGoodItemType => (
                                <MenuItem
                                  value={JSON.stringify(finishedGoodItemType)}
                                  key={finishedGoodItemType?.id ?? ''}
                                >
                                  {finishedGoodItemType?.name ?? ''}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.finishedGoodWeightPerPiece &&
                                errors.finishedGoodWeightPerPiece
                            )}
                            fullWidth
                            helperText={
                              touched.finishedGoodWeightPerPiece &&
                              errors.finishedGoodWeightPerPiece
                            }
                            label='Weight Per Piece'
                            name='finishedGoodWeightPerPiece'
                            onBlur={handleBlur}
                            onChange={e => {
                              if (!e.target.value) {
                                setFieldValue('finishedGoodWeightPerPiece', 0)
                              } else {
                                setFieldValue(
                                  'finishedGoodWeightPerPiece',
                                  e.target.value
                                )
                              }
                            }}
                            InputProps={{
                              inputComponent: DecimalFormat as any
                            }}
                            value={values?.finishedGoodWeightPerPiece || ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            In Stock:{' '}
                            {format(
                              values?.finishedGoodsInventory
                                ?.filter(
                                  finishedGoodsInventory =>
                                    finishedGoodsInventory.forkliftPalletLocation
                                      ?.specialIdentifier !== 'TRUCK'
                                )
                                .reduce(
                                  (accumulator, currentValue) =>
                                    accumulator + currentValue.quantity!,
                                  0
                                )
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Die
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(touched.dieSkuNumber && errors.dieSkuNumber)}
                            fullWidth
                            helperText={touched.dieSkuNumber && errors.dieSkuNumber}
                            label='SKU'
                            name='dieSkuNumber'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.dieSkuNumber || ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.dieItemDescription && errors.dieItemDescription
                            )}
                            fullWidth
                            helperText={
                              touched.dieItemDescription && errors.dieItemDescription
                            }
                            label='Item Description'
                            name='dieItemDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.dieItemDescription || ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.dieGenericDescription &&
                                errors.dieGenericDescription
                            )}
                            fullWidth
                            helperText={
                              touched.dieGenericDescription &&
                              errors.dieGenericDescription
                            }
                            label='Generic Description'
                            name='dieGenericDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.dieGenericDescription || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              disabled={isSubmitting}
                              error={Boolean(touched.dieItemType && errors.dieItemType)}
                              fullWidth
                              helperText={touched.dieItemType && errors.dieItemType}
                              label='Item Type'
                              name='dieItemType'
                              onBlur={handleBlur}
                              onChange={e => {
                                setFieldValue('dieItemType', JSON.parse(e.target.value))
                              }}
                              select
                              value={
                                values?.dieItemType
                                  ? JSON.stringify(
                                      finishedGoodItemTypesList.filter(
                                        finishedGoodItemType =>
                                          finishedGoodItemType?.id ===
                                          values?.dieItemType?.id
                                      )[0]
                                    )
                                  : ''
                              }
                            >
                              {finishedGoodItemTypesList.map(finishedGoodItemType => (
                                <MenuItem
                                  value={JSON.stringify(finishedGoodItemType)}
                                  key={finishedGoodItemType?.id ?? ''}
                                >
                                  {finishedGoodItemType?.name ?? ''}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Print Plate
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.printPlateSkuNumber && errors.printPlateSkuNumber
                            )}
                            fullWidth
                            helperText={
                              touched.printPlateSkuNumber && errors.printPlateSkuNumber
                            }
                            label='SKU'
                            name='printPlateSkuNumber'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.printPlateSkuNumber || ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.printPlateItemDescription &&
                                errors.printPlateItemDescription
                            )}
                            fullWidth
                            helperText={
                              touched.printPlateItemDescription &&
                              errors.printPlateItemDescription
                            }
                            label='Item Description'
                            name='printPlateItemDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.printPlateItemDescription || ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.printPlateGenericDescription &&
                                errors.printPlateGenericDescription
                            )}
                            fullWidth
                            helperText={
                              touched.printPlateGenericDescription &&
                              errors.printPlateGenericDescription
                            }
                            label='Generic Description'
                            name='printPlateGenericDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.printPlateGenericDescription || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              disabled={isSubmitting}
                              error={Boolean(
                                touched.printPlateItemType && errors.printPlateItemType
                              )}
                              fullWidth
                              helperText={
                                touched.printPlateItemType && errors.printPlateItemType
                              }
                              label='Item Type'
                              name='printPlateItemType'
                              onBlur={handleBlur}
                              onChange={e => {
                                setFieldValue(
                                  'printPlateItemType',
                                  JSON.parse(e.target.value)
                                )
                              }}
                              select
                              value={
                                values?.printPlateItemType
                                  ? JSON.stringify(
                                      finishedGoodItemTypesList.filter(
                                        finishedGoodItemType =>
                                          finishedGoodItemType?.id ===
                                          values?.printPlateItemType?.id
                                      )[0]
                                    )
                                  : ''
                              }
                            >
                              {finishedGoodItemTypesList.map(finishedGoodItemType => (
                                <MenuItem
                                  value={JSON.stringify(finishedGoodItemType)}
                                  key={finishedGoodItemType?.id ?? ''}
                                >
                                  {finishedGoodItemType?.name ?? ''}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Sheet
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Vendor Item Number:{' '}
                            {format(values?.vendorOrder?.vendorItemNumber)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Item Description:{' '}
                            {format(
                              values?.vendorOrder?.sheetItemDescription,
                              undefined,
                              'Not Set: Configured On Raw Inventory Details'
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Generic Description:{' '}
                            {format(
                              values?.vendorOrder?.sheetGenericDescription,
                              undefined,
                              'Not Set: Configured On Raw Inventory Details'
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Item Type:{' '}
                            {format(
                              values?.vendorOrder?.sheetItemType?.name,
                              undefined,
                              'Not Set: Configured On Raw Inventory Details'
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Sheet Dimensions
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Gross Width:{' '}
                            {format(values?.vendorOrder?.quoteLineItem?.grossWidth)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Gross Length:{' '}
                            {format(values?.vendorOrder?.quoteLineItem?.grossLength)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Gross SQFT:{' '}
                            {format(values?.vendorOrder?.quoteLineItem?.grossSquareFeet)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            NET Width:{' '}
                            {format(values?.vendorOrder?.quoteLineItem?.netWidth)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            NET Length:{' '}
                            {format(values?.vendorOrder?.quoteLineItem?.netLength)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Net SQFT:{' '}
                            {format(values?.vendorOrder?.quoteLineItem?.netSquareFeet)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            MSF per Unit:{' '}
                            {format(values?.vendorOrder?.quoteLineItem?.msfPerUnit)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Paper
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {/* <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Vendor: {format(values?.vendorOrder?.quoteLineItem?.vendor?.name)}
                          </Typography> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {/* <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Board: {format(values?.vendorOrder?.quoteLineItem?.board?.name)}
                          </Typography> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Flute: {format(values?.vendorOrder?.quoteLineItem?.flute)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            ECT / Mullen:{' '}
                            {format(values?.vendorOrder?.quoteLineItem?.ectMullen)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Adders:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem?.quotePaperAdders
                                ?.map(paperAdder => paperAdder.adder)
                                .join()
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Blend
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Adders:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem?.quoteBlendAdders
                                ?.map(blendAdder => blendAdder.adder)
                                .join()
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Assembly
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Finished Good Unit Quantity (Bundle):{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.assemblyFinishedGoodUnitQuantityPerBundle
                            )}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            MSF Unit:{' '}
                            {format(values?.vendorOrder?.quoteLineItem?.assemblyMsf)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Basis Weight:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem?.assemblyBasisWeight
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Weight MPCS:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem?.assemblyWeightMpcs
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Ton per MPCS:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem?.assemblyTonPerMpcs
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Style:{' '}
                            {format(values?.vendorOrder?.quoteLineItem?.assemblyStyle)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Joint:{' '}
                            {format(values?.vendorOrder?.quoteLineItem?.assemblyJoint)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Unit Type:{' '}
                            {format(values?.vendorOrder?.quoteLineItem?.assemblyUnitType)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Adders:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem?.quoteAssemblyAdders
                                ?.map(assemblyAdder => assemblyAdder.adder)
                                .join()
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Scoring
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 1:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring1,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 2:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring2,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 3:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring3,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 4:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring4,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 5:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring5,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 6:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring6,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 7:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring7,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 8:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring8,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 9:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring9,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 10:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring10,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 11:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring11,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 12:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring12,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 13:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring13,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 14:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring14,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 15:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring15,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Corrugator Scoring 16:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem
                                ?.scoringCorrugatorScoring16,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Adders:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem?.quoteScoringAdders
                                ?.map(scoringAdder => scoringAdder.adder)
                                .join()
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Cumulative Width:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem?.scoringCumulativeWidth,
                              formats.roundedSixteenthsFraction
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Ink
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(touched.inkSkuNumber && errors.inkSkuNumber)}
                            fullWidth
                            helperText={touched.inkSkuNumber && errors.inkSkuNumber}
                            label='SKU'
                            name='inkSkuNumber'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.inkSkuNumber || ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.inkItemDescription && errors.inkItemDescription
                            )}
                            fullWidth
                            helperText={
                              touched.inkItemDescription && errors.inkItemDescription
                            }
                            label='Item Description'
                            name='inkItemDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.inkItemDescription || ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.inkGenericDescription &&
                                errors.inkGenericDescription
                            )}
                            fullWidth
                            helperText={
                              touched.inkGenericDescription &&
                              errors.inkGenericDescription
                            }
                            label='Generic Description'
                            name='inkGenericDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.inkGenericDescription || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              disabled={isSubmitting}
                              error={Boolean(touched.inkItemType && errors.inkItemType)}
                              fullWidth
                              helperText={touched.inkItemType && errors.inkItemType}
                              label='Item Type'
                              name='inkItemType'
                              onBlur={handleBlur}
                              onChange={e => {
                                setFieldValue('inkItemType', JSON.parse(e.target.value))
                              }}
                              select
                              value={
                                values?.inkItemType
                                  ? JSON.stringify(
                                      finishedGoodItemTypesList.filter(
                                        finishedGoodItemType =>
                                          finishedGoodItemType?.id ===
                                          values?.inkItemType?.id
                                      )[0]
                                    )
                                  : ''
                              }
                            >
                              {finishedGoodItemTypesList.map(finishedGoodItemType => (
                                <MenuItem
                                  value={JSON.stringify(finishedGoodItemType)}
                                  key={finishedGoodItemType?.id ?? ''}
                                >
                                  {finishedGoodItemType?.name ?? ''}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Coverage Percentage:{' '}
                            {format(
                              values?.vendorOrder?.quoteLineItem?.inksCoveragePercentage,
                              formats.percent
                            )}
                          </Typography>
                        </Grid>
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel1Color1 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 1, Color 1: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel1Color1
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel1Color1?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel1Color2 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 1, Color 2: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel1Color2
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel1Color2?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel1Color3 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 1, Color 3: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel1Color3
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel1Color3?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel1Color4 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 1, Color 4: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel1Color4
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel1Color4?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel2Color1 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 2, Color 1: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel2Color1
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel2Color1?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel2Color2 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 2, Color 2: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel2Color2
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel2Color2?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel2Color3 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 2, Color 3: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel2Color3
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel2Color3?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel2Color4 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 2, Color 4: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel2Color4
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel2Color4?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel3Color1 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 3, Color 1: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel3Color1
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel3Color1?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel3Color2 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 3, Color 2: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel3Color2
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel3Color2?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel3Color3 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 3, Color 3: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel3Color3
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel3Color3?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel3Color4 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 3, Color 4: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel3Color4
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel3Color4?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel4Color1 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 4, Color 1: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel4Color1
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel4Color1?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel4Color2 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 4, Color 2: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel4Color2
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel4Color2?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel4Color3 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 4, Color 3: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel4Color3
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel4Color3?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {values?.vendorOrder?.quoteLineItem?.ratesInkPanel4Color4 && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Ink Panel 4, Color 4: GCMI:{' '}
                                {
                                  values?.vendorOrder?.quoteLineItem?.ratesInkPanel4Color4
                                    ?.gcmiColors
                                }
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  color: '#7F7F7F',
                                  backgroundColor:
                                    values?.vendorOrder?.quoteLineItem
                                      ?.ratesInkPanel4Color4?.hexCode,
                                  border: '1px solid #7F7F7F'
                                }}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      Updating this form updates these values for the associated job, not
                      individual Finished Goods Inventory items.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DetailsTab>

              <DetailsTab value='tab2'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              In Stock
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} sm={1}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Bundle #
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Quantity
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Banded Date
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Location
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} />
                        </Grid>
                        {values.finishedGoodsInventory
                          ?.filter(
                            finishedGoodsInventoryItem =>
                              finishedGoodsInventoryItem.forkliftPalletLocation
                                ?.specialIdentifier !== 'TRUCK'
                          )
                          .map((finishedGoodsInventoryItem, index) => (
                            <Grid container item xs={12}>
                              <Grid item xs={12} sm={1}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {finishedGoodsInventoryItem.bundleNumber}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={1}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {finishedGoodsInventoryItem.quantity}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {format(
                                    finishedGoodsInventoryItem.createdDateTimeUtc,
                                    formats.dateTime
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {
                                    finishedGoodsInventoryItem.forkliftPalletLocation
                                      ?.description
                                  }
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Button
                                  variant='outlined'
                                  onClick={() => {
                                    setFinishedGoodsInventory(finishedGoodsInventoryItem)
                                    setFinishedGoodsInventoryReconciliationDialogOpen(
                                      true
                                    )
                                  }}
                                  disabled={!CAN_RECONCILE_FINISHED_GOODS_INVENTORY}
                                >
                                  Reconcile
                                </Button>
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Button
                                  variant='outlined'
                                  onClick={() => {
                                    setFinishedGoodsInventory(finishedGoodsInventoryItem)
                                    setFinishedGoodsInventoryScrapDialogOpen(true)
                                  }}
                                  disabled={!CAN_SCRAP_FINISHED_GOODS_INVENTORY}
                                >
                                  Scrap
                                </Button>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Shipped
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} sm={1}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Bundle #
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Quantity
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Banded Date
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Location
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} />
                        </Grid>
                        {values.finishedGoodsInventory
                          ?.filter(
                            finishedGoodsInventoryItem =>
                              finishedGoodsInventoryItem.forkliftPalletLocation
                                ?.specialIdentifier == 'TRUCK'
                          )
                          .map((finishedGoodsInventoryItem, index) => (
                            <Grid container item xs={12}>
                              <Grid item xs={12} sm={1}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {finishedGoodsInventoryItem.bundleNumber}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={1}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {finishedGoodsInventoryItem.quantity}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {format(
                                    finishedGoodsInventoryItem.createdDateTimeUtc,
                                    formats.dateTime
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {
                                    finishedGoodsInventoryItem.forkliftPalletLocation
                                      ?.description
                                  }
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={4} />
                            </Grid>
                          ))}
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      All bundles associated with this job are listed here.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DetailsTab>
              <DetailsActions>
                <Button
                  disabled={isLoading}
                  color='secondary'
                  onClick={() => {
                    navigate('/finished-goods-inventory')
                  }}
                  variant='text'
                >
                  BACK
                </Button>

                <LoadingButton
                  loading={isLoading}
                  color='primary'
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </LoadingButton>
              </DetailsActions>
            </Details>
          )
        }}
      </Formik>
    </Layout>
  )
}
