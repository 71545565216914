import {
  Document,
  Font,
  PDFViewer,
  Page,
  Text,
  View // Import the Image component from @react-pdf/renderer
} from '@react-pdf/renderer'
import {
  CompanyDto,
  JobDto,
  OpportunityDto,
  QuoteLineItemDto,
  VendorOrderDto
} from 'dtos'
import moment from 'moment'
import { format, formats } from 'theme'
import {
  generateBarcodeImage,
  reportTravelerStyles
} from './ReportTravelerExceptLastPage'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: process.env.PUBLIC_URL + '/Roboto-Regular.ttf'
    },
    {
      src: process.env.PUBLIC_URL + '/Roboto-Bold.ttf',
      fontWeight: 'bold'
    }
  ]
})

interface ReportTravelerLastPageProps {
  opportunity: OpportunityDto
  vendorOrderId: string
  jobId: string
  lastBandingQuantity?: number
  stopCount: number
}

const ReportTravelerLastPage = ({
  opportunity,
  vendorOrderId,
  jobId,
  lastBandingQuantity,
  stopCount
}: ReportTravelerLastPageProps) => {
  class PageDto {
    customerName: string = ''
    customerNumber: string = ''
    skuNumber: string = ''
    jobNumber: string = ''
    quantity: string = ''
    bundleNumber: string = ''
    date: string = ''
  }

  let dateGenerated = format(moment(), formats.date)
  let opportunityQuoteRevisionLineItem =
    opportunity.quote?.quoteRevision?.quoteLineItem ?? new QuoteLineItemDto()
  let vendorOrder =
    opportunityQuoteRevisionLineItem?.vendorOrders?.find(
      vendorOrder => vendorOrder.id === vendorOrderId
    ) ?? new VendorOrderDto()
  let job = vendorOrder?.jobs?.find(job => job.id === jobId) ?? new JobDto()
  let company = opportunity.targetCompany ?? new CompanyDto()

  var page: PageDto = {
    customerName: company.name.toUpperCase(),
    customerNumber: (
      opportunityQuoteRevisionLineItem.customerPartNumber ?? ''
    ).toUpperCase(),
    skuNumber: opportunityQuoteRevisionLineItem.skuNumber ?? '',
    jobNumber: job.jobNumber?.toString() ?? '',
    quantity: lastBandingQuantity?.toString() ?? '',
    // stopCount is the number of times the job has been marked stopped or completed, including the time that generated this report
    // Subtract 1 so that we don't increment past the expected last pallet number unless marked stopped or compelted more than once
    bundleNumber:
      vendorOrder?.projectedBundles !== undefined
        ? (vendorOrder?.projectedBundles + stopCount - 1).toString()
        : '',
    date: dateGenerated
  }

  return (
    <PDFViewer style={{ width: '100%', height: '100%' }}>
      <Document>
        <Page size='LEGAL' style={reportTravelerStyles.page}>
          <View>
            <Text style={reportTravelerStyles.topMargin}>{''}</Text>
          </View>
          <View>
            <View style={reportTravelerStyles.horizontalLine} />
            <Text style={reportTravelerStyles.cellCenterSmall}>FOLD HERE</Text>
            <Text style={reportTravelerStyles.spacingBlock}>
              <Text style={reportTravelerStyles.cellLeftSmall}>CUSTOMER: </Text>
              <Text style={reportTravelerStyles.cellLeftMediumBold}>
                {page.customerName}
              </Text>
            </Text>
            <View style={reportTravelerStyles.horizontalLine} />
            <Text style={reportTravelerStyles.spacingBlock}>
              <Text style={reportTravelerStyles.cellLeftSmall}>CUSTOMER #: </Text>
              <Text style={reportTravelerStyles.cellLeftMediumBold}>
                {page.customerNumber}
              </Text>
            </Text>
            <Text style={reportTravelerStyles.spacingBlock}>{''}</Text>
            <Text style={reportTravelerStyles.cellLeftMedium}>PART #:</Text>
            {generateBarcodeImage(page.skuNumber)}
            <Text style={reportTravelerStyles.cellLeftExtraLargeBold}>
              {page.skuNumber}
            </Text>
            <Text style={reportTravelerStyles.cellRightMedium}>JOB #:</Text>
            <View style={{ flexDirection: 'row-reverse' }}>
              {generateBarcodeImage(page.jobNumber)}
            </View>
            <Text style={reportTravelerStyles.cellRightExtraLargeBold}>
              {page.jobNumber}
            </Text>
            <Text style={reportTravelerStyles.spacingBlock}>{''}</Text>
            <View style={reportTravelerStyles.row}>
              <Text style={reportTravelerStyles.cellLeftMedium}>QUANTITY:</Text>
              <Text style={reportTravelerStyles.cellRightMedium}>BUNDLE #:</Text>
            </View>
            <View style={reportTravelerStyles.row}>
              {generateBarcodeImage(page.quantity)}
              {generateBarcodeImage(page.bundleNumber)}
            </View>
            <View style={reportTravelerStyles.row}>
              <Text style={reportTravelerStyles.cellLeftExtraLargeBold}>
                {page.quantity}
              </Text>
              <Text style={reportTravelerStyles.cellRightExtraLargeBold}>
                {page.bundleNumber}
              </Text>
            </View>
            <Text style={reportTravelerStyles.spacingBlock}>{''}</Text>
            <Text style={reportTravelerStyles.cellLeftSmall}>
              DATE: {format(page.date, formats.date)}
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default ReportTravelerLastPage
