import { createTheme, Theme as MuiTheme } from '@mui/material/styles'
import { AddressDto } from 'dtos'
import moment from 'moment'
const dateOnlyStringRegex = /^\d{4}-\d{2}-\d{2}$/

// The muiTheme is the MUI theme without custom variables.
const muiTheme: MuiTheme = createTheme({
  components: {
    MuiCard: {
      defaultProps: {
        variant: 'elevation',
        elevation: 4
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: theme => ({
          paddingBottom: theme.theme.spacing(2) + ' !important'
        })
      }
    }
  },
  zIndex: {
    appBar: 1101,
    drawer: 1100
  }
})

interface BaseTheme extends MuiTheme {
  drawer: {
    width: number
  }
}

// theme is the extended theme with custom variables.
// It outlines common styles across both light and dark themes.
export const baseTheme: BaseTheme = {
  ...muiTheme,
  drawer: {
    width: 280
  }
}

interface Formats {
  address: string
  currency: Intl.NumberFormat
  currencyFourDecimalPlaces: Intl.NumberFormat
  date: string
  dateNoTimeZone: string
  dateOnlyField: string
  dateTime: string
  weekDayDate: string
  percent: Intl.NumberFormat
  phone: string
  roundedSixteenthsFraction: string
  time: string
  timeOnlyField: string
}

export const formats: Formats = {
  address: 'address',
  currency: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0,
    //maximumFractionDigits: 0,
  }),
  currencyFourDecimalPlaces: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 4,
    maximumFractionDigits: 4
  }),
  date: 'M/D/YYYY',
  dateNoTimeZone: 'dateNoTimeZone',
  dateOnlyField: 'yyyy-MM-DD',
  dateTime: 'M/D/YYYY h:mm A',
  weekDayDate: 'ddd. M/D/YYYY',
  percent: new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4
  }),
  phone: '(###) ###-####',
  roundedSixteenthsFraction: 'roundedSixteenthsFraction',
  time: 'h:mm A',
  timeOnlyField: 'HH:mm' // 24-hour time format with leading 0
}

export const format = (
  value: any,
  type: Formats[keyof Formats] | undefined = undefined,
  emptyValueOverride: string | undefined = undefined
): string => {
  switch (type) {
    case formats.address:
      if (value !== undefined || value instanceof AddressDto) {
        return (
          value.address1 +
          (value.address2 === null ? '' : '\n' + value.address2 + ', ') +
          (value.address3 === null ? '' : value.address3) +
          '\n' +
          value.city +
          ', ' +
          value.state +
          ' ' +
          value.postalCode +
          ', ' +
          value.country +
          (value.email === null ? '' : '\n' + value.email) +
          (value.phone === null ? '' : '\n' + value.phone)
        )
      }
      break

    case formats.currency:
      if (value || value === 0) {
        return formats.currency.format(value)
      }
      break

    case formats.date:
      if (value || value === 0) {
        if (typeof value === 'string' && value.match(dateOnlyStringRegex)) {
          // If values is in the format 2000-01-01, format it so as to ignore time zone and just return the formatted date
          const date = new Date(value + 'T00:00:00')
          const month = date.getMonth() + 1 // Months are 0-based in JavaScript Date
          const day = date.getDate()
          const year = date.getFullYear()
          return `${month}/${day}/${year}`
        } else {
          // Otherwise, format it using momentJs
          var dateUtc = moment.utc(value).toDate()
          return moment(dateUtc).local().format(formats.date)
        }
      }
      break

    case formats.dateNoTimeZone:
      if (value || value === 0) {
        return moment(value).format(formats.date)
      }
      break

    case formats.dateTime:
      if (value || value === 0) {
        var dateUtc = moment.utc(value).toDate()
        return moment(dateUtc).local().format(formats.dateTime)
      }
      break

    case formats.percent:
      if (value || value === 0) {
        return formats.percent.format(value / 100)
      }
      break

    case formats.roundedSixteenthsFraction:
      if (value || value === 0) {
        if (value % 1 == 0) {
          return value.toString()
        } else {
          return (
            Math.floor(value).toString() +
            '-' +
            Math.round((value % 1) * 16).toString() +
            '/16'
          )
        }
      }
      break

    default:
      if (value || value === 0) {
        return value
      }
      break
  }
  return emptyValueOverride === undefined ? 'Not Set' : emptyValueOverride
}
