import {
  ApiResponse,
  BandingDto,
  ForkliftToDoDto,
  ForkliftToDoMoveFinishedGoodsInventoryDto,
  JobDto
} from 'dtos'
import { FinishedGoodsInventoryParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getFinishedGoodsInventory = (parameters: FinishedGoodsInventoryParameters) =>
  apiClient
    .get<ApiResponse<JobDto[]>>(
      '/api/finished-goods-inventory' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getFinishedGoodsInventoryByJobId = (jobId: string) =>
  apiClient
    .get<ApiResponse<JobDto>>('/api/finished-goods-inventory/' + jobId)
    .then(({ data }) => data)

export const createFinishedGoodsFromBanding = (values: BandingDto) =>
  apiClient
    .post<ApiResponse<boolean>>('/api/finished-goods-inventory/bander', values)
    .then(({ data }) => data)

export const updateFinishedGoodsInventory = (values: JobDto) =>
  apiClient
    .put<ApiResponse<JobDto>>('/api/finished-goods-inventory', values)
    .then(({ data }) => data)

export const createUnquotedFinishedGoodsInventory = (
  values: ForkliftToDoMoveFinishedGoodsInventoryDto
) =>
  apiClient
    .post<ApiResponse<ForkliftToDoMoveFinishedGoodsInventoryDto>>(
      '/api/finished-goods-inventory/unquoted',
      values
    )
    .then(({ data }) => data)
