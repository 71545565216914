import { ApiResponse, ForkliftToDoDto } from 'dtos'
import apiClient from '.'
import { ForkliftToDosParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getForkliftToDos = (parameters: ForkliftToDosParameters) =>
  apiClient
    .get<ApiResponse<ForkliftToDoDto[]>>(
      '/api/forklift-to-dos' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getForkliftToDoById = (id: string) =>
  apiClient
    .get<ApiResponse<ForkliftToDoDto>>('/api/forklift-to-dos/' + id)
    .then(({ data }) => data)

export const updateForkliftToDo = (values: ForkliftToDoDto) =>
  apiClient
    .put<ApiResponse<boolean>>('/api/forklift-to-dos', values)
    .then(({ data }) => data)

export const forkliftToDoMoveRawInventory = (values: ForkliftToDoDto) =>
  apiClient
    .put<ApiResponse<boolean>>('/api/forklift-to-dos/move-raw-inventory', values)
    .then(({ data }) => data)

// Returns true if the FinishedGoodsInventory exists and has been moved
// Returns false if the FinishedGoodsInventory does not exist and should be created
export const forkliftToDoCheckMoveFinishedGoodsInventory = (values: ForkliftToDoDto) =>
  apiClient
    .put<ApiResponse<boolean>>(
      '/api/forklift-to-dos/check-move-finished-goods-inventory',
      values
    )
    .then(({ data }) => data)
