import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import * as React from 'react'
import { ListChildComponentProps, VariableSizeList } from 'react-window'

const LISTBOX_PADDING = 8

function renderRowCompany(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  }
  return (
    <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
      {`${dataSet[1].name}`}
    </Typography>
  )
}

function renderRowPerson(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  }
  return (
    <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
      {`${dataSet[1].firstName + ' ' + dataSet[1].lastName}`}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
export const ListboxComponentCompany = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  })
  const itemCount = itemData.length
  // Make items 36 pixels tall on a "small" or larger screen, and 48 piels tall otherwise
  const itemSize = smUp ? 36 : 48

  // Only show a maximum of 8 items in the dropdown
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemCount * itemSize
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          // Every item is the same height, so don't set the itemSize individually for each index
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRowCompany}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

// Adapter for react-window
export const ListboxComponentPerson = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  })
  const itemCount = itemData.length
  // Make items 36 pixels tall on a "small" or larger screen, and 48 piels tall otherwise
  const itemSize = smUp ? 36 : 48

  // Only show a maximum of 8 items in the dropdown
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemCount * itemSize
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          // Every item is the same height, so don't set the itemSize individually for each index
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRowPerson}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})
