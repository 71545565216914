import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import { OpportunityDto, QuoteRevisionDto, ReportQuoteDto } from 'dtos'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { format, formats } from 'theme'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    fontSize: 8
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  table: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    alignItems: 'center'
  },
  headerCell: {
    width: '33%',
    textAlign: 'center'
  },
  cell: {
    width: '33%',
    textAlign: 'center'
  },
  textBlock: {
    marginBottom: 10
  }
})

interface ReportQuoteProps {
  reportQuoteDto: ReportQuoteDto
}

export default function ReportQuote({ reportQuoteDto }: ReportQuoteProps) {
  const [opportunity, setOpportunity] = useState<OpportunityDto>(new OpportunityDto())
  const [quoteRevision, setQuoteRevision] = useState<QuoteRevisionDto>(
    new QuoteRevisionDto()
  )

  useEffect(() => {
    setOpportunity(reportQuoteDto.opportunity ?? new OpportunityDto())
    setQuoteRevision(
      reportQuoteDto.opportunity?.quote?.quoteRevision ?? new QuoteRevisionDto()
    )
  }, [reportQuoteDto])

  return (
    <div>
      <PDFViewer style={{ width: '100%', height: '100%' }}>
        <Document>
          <Page size='LETTER' style={styles.page}>
            <View style={styles.section}>
              <Image
                src={process.env.PUBLIC_URL + '/nglogo.jpg'}
                style={{ width: 250, height: 75 }}
              />

              {reportQuoteDto.isPreviousRevision ? (
                <Text
                  // This styling is somewhat arbitrary and can be adjusted as needed.
                  style={{
                    position: 'absolute',
                    top: '40%',
                    color: 'rgba(127, 127, 127, 0.4)',
                    fontSize: 75,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    transform: 'rotate(-45deg)'
                  }}
                >
                  VOIDED QUOTE
                </Text>
              ) : null}

              <Text style={styles.textBlock}>{''}</Text>
              <Text style={styles.textBlock}>
                Quotation Ref # {quoteRevision?.quoteLineItem?.quotationRefNumber}
              </Text>
              <Text style={styles.textBlock}>{format(moment(), formats.date)}</Text>

              <Text style={styles.textBlock}>
                Thank you for allowing JackPack the opportunity to evaluate and present
                pricing for {opportunity?.opportunityName}. JackPack values the
                partnership we have in custom designing and supplying the{' '}
                {opportunity?.opportunityName} system/solution to{' '}
                {opportunity?.targetCompany?.name}. JackPack seeks to build and strengthen
                this partnership by providing solutions in the optimal format for your
                teams. Please find the quotation below. Notes and Assumptions for
                Quotation:
              </Text>

              <Text style={styles.textBlock}>
                • Payment Terms: {quoteRevision?.paymentTerms + '\n'}• Currency:{' '}
                {quoteRevision?.currency + '\n'}• Method of Shipment:{' '}
                {quoteRevision?.methodOfShipment + '\n'}• Lead Time for Each Order:{' '}
                {quoteRevision?.leadTimeForEachOrder + '\n'}• Test Run Quantity:{' '}
                {quoteRevision?.quoteLineItem?.testRunQuantity + '\n'}• Minimum Order Run
                Quantity: {quoteRevision?.quoteLineItem?.minimumOrderRunQuantity + '\n'}•
                Committed Quantity:{' '}
                {quoteRevision?.quoteLineItem?.committedQuantity + '\n'}• Contract
                Duration: {quoteRevision?.quoteLineItem?.contractDuration + ' Months\n'}•
                Quotation is based upon the annual volume stated by{' '}
                {opportunity?.targetCompany?.name} received by JackPack on{' '}
                {format(opportunity?.quote?.rfqReceivedDate, formats.date)}. Due to this
                annual volume, a lead time of {quoteRevision?.leadTimeForEachOrder} is
                required for all orders. Should the actual volume vary significantly from
                what was stated, JackPack reserves the right to review pricing and present
                a revised quotation.
              </Text>

              <View style={styles.table}>
                <Text
                  style={{ ...styles.headerCell, borderWidth: 1, borderColor: '#000' }}
                >
                  Item Identification
                </Text>
                <Text
                  style={{ ...styles.headerCell, borderWidth: 1, borderColor: '#000' }}
                >
                  Size
                </Text>
                <Text
                  style={{ ...styles.headerCell, borderWidth: 1, borderColor: '#000' }}
                >
                  Style/Grade
                </Text>
                <Text
                  style={{ ...styles.headerCell, borderWidth: 1, borderColor: '#000' }}
                >
                  Joint
                </Text>
                <Text
                  style={{ ...styles.headerCell, borderWidth: 1, borderColor: '#000' }}
                >
                  Quantity +/- 10%
                </Text>
                <Text
                  style={{ ...styles.headerCell, borderWidth: 1, borderColor: '#000' }}
                >
                  Price/M Pcs.
                </Text>
              </View>
              <View style={styles.table}>
                <Text style={{ ...styles.cell }}>
                  {quoteRevision?.quoteLineItem?.skuNumber + '\n'} - Spec Date{' '}
                  {format(quoteRevision?.quoteLineItem?.specDate, formats.date)}
                </Text>

                <Text style={{ ...styles.cell }}>
                  {quoteRevision?.quoteLineItem?.netWidth} x{' '}
                  {quoteRevision?.quoteLineItem?.netLength} -{' '}
                  {
                    quoteRevision?.quoteLineItem
                      ?.assemblyFinishedGoodUnitQuantityPerBundle
                  }
                </Text>

                <Text style={{ ...styles.cell }}>
                  {quoteRevision?.quoteLineItem?.assemblyStyle} -{' '}
                  {quoteRevision?.quoteLineItem?.ectMullen} ECT{' '}
                  {quoteRevision?.quoteLineItem?.flute}
                </Text>

                <Text style={{ ...styles.cell }}>
                  {quoteRevision?.quoteLineItem?.assemblyJoint}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {quoteRevision?.quoteLineItem?.assemblyCustomerOrderQuantity}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {format(
                    quoteRevision?.quoteLineItem?.summaryTotalSellPriceCostPerMpcs,
                    formats.currency
                  )}
                </Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <Text style={styles.textBlock}>
                The quotation included within this document remains valid until{' '}
                {format(quoteRevision?.quoteValidUntil, formats.date)} after which time a
                new review would need to be completed for validation.
              </Text>
              <Text style={styles.textBlock}>
                Please let us know if you have any questions. We look forward to
                continuing to provide {opportunity?.targetCompany?.name} with excellent
                packaging solutions to meet the demands and expectations of your business
                for both today and the future.
              </Text>
              <Text style={styles.textBlock}>Sincerely,</Text>
              <Text style={styles.textBlock}>
                {opportunity?.salesperson?.firstName} {opportunity?.salesperson?.lastName}
              </Text>
              <Text style={styles.textBlock}>{opportunity?.salesperson?.title}</Text>
              <Text style={styles.textBlock}>
                Email: {opportunity?.salesperson?.email}
              </Text>
              <Text style={styles.textBlock}>
                Mobile: {opportunity?.salesperson?.mobilePhone}
              </Text>
              <Text style={styles.textBlock}>
                Office: {opportunity?.salesperson?.workPhone}
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  )
}
