import { ApiResponse, PersonDto } from 'dtos'
import { PeopleParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getPeople = (parameters: PeopleParameters) =>
  apiClient
    .get<ApiResponse<PersonDto[]>>('/api/people' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getPersonSalespeople = () =>
  apiClient
    .get<ApiResponse<PersonDto[]>>('/api/people/salespeople')
    .then(({ data }) => data)

export const getPersonCompanyPeopleForJackPack = () =>
  apiClient
    .get<ApiResponse<PersonDto[]>>('/api/people/jackpack-company-people')
    .then(({ data }) => data)

export const getPersonCompanyPeopleByCompanyId = (companyId: string) =>
  apiClient
    .get<ApiResponse<PersonDto[]>>('/api/people/company-people' + URIQuery({ companyId }))
    .then(({ data }) => data)
